<template>
  <div>

    <banner
        :company-name="'禾祺科技'"
        :title="'企业招聘'"
        :description="'成为我们的一员'"
        :background-url="require('@/assets/images/nav/pexels-photo-5439148.png')"
    ></banner>
    <div class="main-box">
      <el-row>
        <el-col :span="12" :offset="6">
          <el-card style="min-height: 1200px">
            <el-row class="title">
              在招职位
            </el-row>
            <el-row style="margin-top: 20px" class="title-box">
              <el-col class="head-title">
                职位筛选
              </el-col>
              <el-col class="head-title-right">
<!--                <span class="light-font">清除</span>-->
<!--                <span class="light-font">|</span>-->
                <span class="light-font">{{showListData.length}}结果</span>

              </el-col>

            </el-row>
            <el-form >
              <el-row style="margin-top: 40px">
                <div style="display: inline-flex;width: 100%" >
                  <el-input
                      v-model="keyword"
                      :disabled="disabled"
                      :placeholder="placeholder"
                      prefix-icon="el-icon-search"
                      style="margin-right: 10px"
                      clearable
                      @clear="search"
                      @keydown.enter.native="search"></el-input>
                  <el-button
                      :disabled="disabled"
                      icon="el-icon-search"
                      type="primary"
                      @click="search">
                    搜索
                  </el-button>
                </div>

              </el-row>

            </el-form>
            <el-row class="item-box" >
              <el-row class="items" v-for="item in showListData" :key="item.id"  @click.native="showDetails(item.id)">
                <el-row style="margin-top: 30px;padding-left: 20px" class="title-box">
                  <el-col class="head-title">
                    <span v-show="item.worry" style="top: 1px; margin-right: 4px; height: 20px; font-size: 12px; line-height: 20px;  color: rgb(251, 100, 100);">急</span>
                      {{ item.title}}

                    <span style="display: inline-block;margin-left: 8px" class="">{{item.address}}</span>

                  </el-col>
                  <el-col class="head-title-right" style="margin-right: 10px">
                    <span class="light-font">{{  item.date }}</span>
                    <span class="light-font">发布于</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col class="address" style="margin-top: 20px;margin-bottom: 10px;padding-left: 20px">
                    <span class="light-font" v-for="(description,index) in item.descriptions " :key="index">
                      {{description}}</span>

<!--                    <span class="light-font">|</span>-->

                  </el-col>

                </el-row>
              </el-row>
            </el-row>

          </el-card>

        </el-col>
      </el-row>


    </div>





  </div>

</template>

<script>
import banner from './components/banner.vue';

export default {
  name: "recruitList",
  components:{
    banner
  },

  data(){
    return {
      disabled:false,
      placeholder:'请输入职位进行搜索，可以直接回车搜索...',
      keyword:'',
      showListData:[],
      listData:[  {
        id:1,
        worry:true,
        title:'档案整理人员',
        date:'2023-11-13',
        descriptions:['档案','|','档案整理','|','档案数字化'],
        address:'[太原·迎泽区·迎泽大街]'
        },
        {
          id:2,
          worry:true,
          title:'项目经理',
          date:'2023-11-13',
          descriptions:['工程档案'],
          address:'[太原·迎泽区·迎泽大街]'
        },
        {
          id:3,
          worry:true,
          title:'档案整理专员',
          date:'2023-11-13',
          descriptions:['档案整理','|','资料','|','数字化','|','内勤','|','服务'],
          address:'[太原·小学店·学府街]'
        },
        // {
        //   id:4,
        //   worry:true,
        //   title:'行政人事主管',
        //   date:'2023-11-13',
        //   descriptions:['管理体系建设优化','|','流程制度定制/优化','|','企业文化建设'],
        //   address:'[太原·迎泽区·迎泽大街]'
        // },

        {
          id:6,
          worry:true,
          title:'行业客户经理',
          date:'2023-11-13',
          descriptions:['政府/社会团体','|','对企业/机构（To B）销售经验','|','互联网/科技'],
          address:'[太原·小学店·学府街]'
        },
        {
          id:7,
          worry:true,
          title:'项目售前支持',
          date:'2023-11-13',
          descriptions:['售前技术支持经验','|','可适应出差','|','计算机/通信相关专业'],
          address:'[太原·小学店·学府街]'
        },
        {   id:5,
          worry:false,
          title:'java开发工程师',
          date:'2023-11-13',
          descriptions:['java开发'],
          address:'[湖南长沙]'
        },
      ]
    }
  },
  created() {
    this.showListData=this.listData



  },
  methods:{
    search(){
      if(this.keyword){
        this.showListData=this.listData.filter(item=>{
          return item.title.indexOf(this.keyword)!=-1
        })
      }else {
        this.showListData=this.listData
      }
      // this.$emit("search",['search',this.keyword])
    },
    showDetails(id){


        // alert(1)
      // this.$router.push({path:'/',query: {id:id}})

      this.$router.push({path:'/recruitItem',query: {id:id}})
    }
  }


}
</script>

<style scoped>
  .main-box{

    width: 100%;
    height: 100%;
    background-color: #f4f4f6;
    //text-align: center;


    margin-top: 68px;
    padding-top: 40px;
    padding-bottom: 5%;
    //margin-bottom: 0;
    //background: url('../assets/images/contact-bg.jpg') no-repeat;
    //background-size: contain;
    //text-align: center;
    //height: 500px;

  }
    .title{
    color: #141933;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
    .title-box{
      display: flex;

      }
    .head-title{
      color: #141933;
      font-weight: 500;
      font-size: 18px;

    }
  .head-title-right{
    margin-top: 6px;
    float: right;
  }
  .head-title-right>span{
     display: inline-block;
      float: right;
    margin-left: 8px;
  }
  .address span{
    display: inline-block;

    margin-left: 8px;
  }
  .light-font{

    color: #8589a6;
  }
  .items{
    position: relative;
    background: #fff;
  }
  .items:hover{
    background-color: #f7f8fa;
  }
  .item-box{
    margin-top: 20px;
    border-bottom: 1px solid #f7f8fa;
  }
  .head-title:hover{
    color: #0068FF;;
  }





</style>