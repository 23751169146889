<template>
  <div>
    <model :featureList="featureList"></model>
  </div>
</template>

<script>
import model from './model.vue';

export default {
  components: {
    model
  },
  data() {
    return {
      featureList: [
        {
          feature: '四性检测',
          description: [
            "新施行的《档案法》第三十七条规定“电子档案与传统载体档案具有同等效力，可以以电子形式作为凭证使用”。这从法律层面明确了电子档案的法律效力，" +
            "可以极大地促进档案工作逐步过渡到“单套制”“单轨制”管理模式。同时它又规定了电子档案具有法律效力的前提是“来源可靠、程序规范、要素合规”。要做到“来源可靠、" +
            "程序规范、要素合规”，保证电子档案的真实性、完整性、可用性和安全性（简称“四性”）是关键。为保证电子档案“四性”，依托信息管理环境，利用技术手段对其进行检测，" +
            "是保证电子档案“四性”的重要措施之一。",
          ],
          src2: require('../../assets/images/solution-img-2.jpg')
        },
      ],
    }
  },
  methods: {},
}
</script>

<style scoped>
</style>