import axios, {request} from 'axios'
// import { Notification, Message,  } from 'element-ui'
// import errorCode from "@/api/errorCode";




axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000
})




// 响应拦截器
// service.interceptors.response.use(res => {
//         // 未设置状态码则默认成功状态
//         const code = res.data.code || 200;
//         // 获取错误信息
//         const msg = errorCode[code] || res.data.msg || errorCode['default']
//         // 二进制数据则直接返回
//         if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
//             return res.data
//         }
//         if (code === 401) {
//
//             return Promise.reject('权限不足')
//         } else if (code === 500) {
//             /*if(res.config.url == '/login'){
//               Message({
//                 message: msg,
//                 type: 'error'
//               })
//             }
//           console.log(msg);
//           return Promise.reject(new Error(msg))*/
//             Message({
//                 message: msg,
//                 type: 'error'
//             })
//             return
//         } else if (code !== 200) {
//             Notification.error({
//                 title: msg
//             })
//             return
//         } else {
//             return res.data
//         }
//     },
//     error => {
//         console.log('err' + error)
//         let { message } = error;
//         if (message == "Network Error") {
//             message = "后端接口连接异常";
//         }
//         else if (message.includes("timeout")) {
//             message = "系统接口请求超时";
//         }
//         else if (message.includes("Request failed with status code")) {
//             message = "系统接口" + message.substr(message.length - 3) + "异常";
//         }
//         Message({
//             message: message,
//             type: 'error',
//             duration: 5 * 1000
//         })
//         return Promise.reject(error)
//     }
// )



export default service

