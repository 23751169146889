import Vue from 'vue'
import VueRouter from 'vue-router'




//引入视图组件
import index from '@/views/index.vue'//@是webpack配置的src的别名，@指的是src的绝对路径
import introduce from '@/views/introduce.vue'
import contact from '@/views/contact.vue'
import cooperation from '@/views/cooperation.vue'
import product from '@/views/product.vue'
import instance from '@/views/instance.vue'
import news from '@/views/news.vue'
import manage from '@/components/tabBlock/manage.vue'
import use from '@/components/tabBlock/use.vue'
import identification from '@/components/tabBlock/identification.vue'
import intelligence from '@/components/tabBlock/intelligence.vue'
import recruitList from "@/views/recruitList.vue";
import recruit from "@/views/components/recruit.vue";
import aptitudeShow from "@/views/aptitudeShow.vue";
import infolistItem from "@/views/components/infolistItem.vue"
import oldnews from "@/views/oldnews.vue"
import solution from "@/views/components/solution.vue"
import minNews from "@/views/components/minNews.vue"
import products from "@/views/components/products.vue"
import digitalArchive from "@/views/components/solution/digitalArchive.vue"

//注册全局组件
Vue.use(VueRouter)


const routes = [
    {
        path: "/",
        redirect: '/index',
    },
    {
        path: "/index",
        component: index
    },
    {
        path: "/product",
        component: product,
        children: [
            {
                path: '/',
                redirect: '/product/manage',
            },
            {
                path: 'manage',
                component: manage
            },
            {
                path: 'use',
                component: use
            },
            {
                path: 'identification',
                component: identification
            },
            {
                path: 'intelligence',
                component: intelligence
            },
        ]
    },
    {
        path: "/contact",
        component: contact
    },
    {
        path: "/introduce",
        component: introduce
    },
    {
        path: "/cooperation",
        component: cooperation
    },
    {
        path: "/instance",
        component: instance
    },
    {
        path: "/news",
        component: news
    },
    //加入我们
    {
        path: "/recruitItem",
        component:recruit
    },
    //加入我们
    {
        path: "/recruitList",
        component:recruitList
    }
    ,
    //企业资质
    {
        path: "/aptitudeShow",
        component:aptitudeShow
    }
    ,
    //新闻具体内容
    {
        path: "/infolistItem",
        component:infolistItem
    }
    ,
    //行业咨询
    {
        path: "/consultation",
        component:oldnews
    },

    //
    {
        path: "/solution",
        component:solution
    }
    ,

    //
    {
        path: "/minNews",
        component:minNews
    },
    {
        path: "/products" ,
        component:products
    },
    {
        path: "/digitalArchive" ,
        component:digitalArchive
    }



]


//生成路由对象
const router = new VueRouter({
    // mode:'history',
    routes //routes是固定的key(传入规则数组)，然后es6允许简写，key和value同名时，写一个
})

export default router