<template>
  <div>
    <!-- 需要轮播背景图时，启动轮播开关，且加上进入和离开的暂停方法pauseTime -->
    <div
      @mouseenter="pauseTimer"
      @mouseleave="pauseTimer"
      class="section-one"

    >
<!--      <div style="height: 68px"></div>-->

<!--      <div style="z-index: 999999999999999999">-->

<!--      </div>-->

            <swiper ref="mySwiper" :options="swiperOptions" class="swiper-Box" >

              <swiper-slide class="swiper-slide-box"   >
<!--                <button class="free-use" @click="showOpen" >免费试用</button>-->
              </swiper-slide>
              <swiper-slide class="swiper-slide-box"   >
                <!--                <button class="free-use" @click="showOpen" >免费试用</button>-->
              </swiper-slide>
<!--              <swiper-slide class="swiper-slide-box"   >-->
<!--                <button class="free-use" @click="showOpen" >免费试用</button>-->
<!--              </swiper-slide>-->
<!--              <swiper-slide class="swiper-slide-box"   >-->
<!--                <button class="free-use" @click="showOpen" >免费试用</button>-->
<!--              </swiper-slide>-->
              <div class="swiper-button-prev" slot="button-prev">
                <img src="../assets/images/navigationImg/left.png">
              </div>
              <div class="swiper-button-next" slot="button-next">
                <img src="../assets/images/navigationImg/right.png">
              </div>
            </swiper>

      <el-dialog title="请填写联系方式,我们为您提供免费试用的账号" :visible.sync="open"  @close="cancel"
                    width="600px"  center   append-to-body >

        <div><span >姓名</span><el-input v-model="userName" placeholder="请输入内容" ></el-input></div>
        <div><span>所属单位</span><el-input v-model="affiliation"  placeholder="请输入内容"></el-input></div>
        <div><span>电话号码</span><el-input  v-model="phone" placeholder="电话号码"></el-input></div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="comimtForm">确 定</el-button>

          <el-button @click="cancel">取 消</el-button>
        </div>

      </el-dialog>
      <!-- 占位盒子 -->
<!--      <div class="content" v-show="active">-->
<!--        <h5>禾祺科技</h5>-->
<!--        <h1>智能化信息管理系统</h1>-->
<!--        <h6>系统涵盖了档案管理全过程，信息的采集、加工、整理、分析、统计和利用</h6>-->
<!--        <button @click="showOpen" >免费试用</button>-->
<!--      </div>-->
<!--      <div class="content" v-show="!active">-->
<!--        <h1>量身定制和通用标准相结合</h1>-->
<!--        <h6>-->
<!--          将最先进的档案管理理论与实际操作有机融合，站在专业角度提升用户档案管理的科学性-->
<!--        </h6>-->
<!--        <h5 style="opacity: 0">占位</h5>-->
<!--        <button @click="showOpen" >免费试用</button>-->
<!--      </div>-->
<!--      <div class="control">-->
<!--        <ul>-->
<!--          <li :class="{ current: active }" @click="slideChange()"></li>-->
<!--          <li :class="{ current: !active }" @click="slideChange()"></li>-->
<!--        </ul>-->
<!--      </div>-->
<!--      <div class="foot" >-->
<!--        <ul>-->
<!--          <li v-for="item in bFootList">-->
<!--            <div class="title">-->
<!--              <img :src="item.src" alt="" class="icon" />{{ item.title }}-->
<!--            </div>-->
<!--            <div class="description">{{ item.descrption }}</div>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
    </div>
<!--    <div class="section-two">-->
<!--      <h2 data-aos="fade-up" >我们为档案馆、高校、政企单位，数千万档案数据提供管理服务</h2>-->
<!--      <h3 data-aos="fade-up">禾祺科技，专业档案管理解决方案</h3>-->
<!--      <h3 data-aos="fade-up">-->
<!--        我们始终专注档案信息化领域，从国标到个性化，全方位提供档案管理专业化服务-->
<!--      </h3>-->
<!--      <div class="imgbox" data-aos="fade-up">-->
<!--        <img src="../assets/images/insert-img1.jpg" />-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="section-two-two" data-aos="fade-up">-->

<!--      <el-table-->
<!--          :data="offerData"-->
<!--          style="width: 100%"-->

<!--      >-->
<!--        <el-table-column-->
<!--            type="index"-->
<!--            width="50"-->
<!--            label="序号">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            prop="name"-->
<!--            label="产品名称">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            prop="system"-->
<!--            label="品牌型号">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            prop="price"-->
<!--            label="价格">-->
<!--        </el-table-column>-->
<!--      </el-table>-->

<!--    </div>-->
<!--    <div class="section-three" >-->
<!--      <h2 data-aos="fade-up">禾祺科技，全流程的档案管理解决方案</h2>-->
<!--      <h3 data-aos="fade-up">-->
<!--        从收、管、存、用到鉴定销毁，从档案保管到OCR在线检索利用，开启档案管理新规范-->
<!--      </h3>-->
<!--      <ul data-aos="fade-up">-->
<!--        <li-->
<!--          :class="{ current: obj.display }"-->
<!--          v-for="(obj, index) in tabControl"-->
<!--          :key="index"-->
<!--          @click="tabShow(index)"-->
<!--        >-->
<!--          {{ obj.title }}-->
<!--        </li>-->
<!--      </ul>-->
<!--      <div-->
<!--        class="content"-->
<!--        v-for="(obj, index) in tabControl"-->
<!--        :key="index"-->
<!--        v-show="obj.display"-->
<!--      >-->
<!--        <div class="left" data-aos="fade-up">-->
<!--          <p class="title">{{ obj.title }}</p>-->
<!--          <p class="description">{{ obj.description }}</p>-->
<!--           <a :href="obj.link" >了解更多</a>-->


<!--        </div>-->
<!--        <div data-aos="fade-up" class="right"><img :src="obj.src" alt="" /></div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="section-five">-->
<!--      <h2 data-aos="fade-up">禾祺科技，为每一个档案行业提升工作效率</h2>-->
<!--      <ul>-->
<!--        <li v-for="obj in identityList" :key="obj.src">-->
<!--          <img :src="obj.src" alt="" />-->
<!--          <div>-->
<!--            <p data-aos="fade-up">{{ obj.identity }}</p>-->
<!--            <p v-html="obj.description" data-aos="fade-up"></p>-->
<!--          </div>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--    <div class="section-six">-->
<!--      <h2 data-aos="fade-up">我们的服务保障</h2>-->
<!--      <p data-aos="fade-up">提供优质的服务支持，重塑档案与使用者的连接</p>-->
<!--      <ul>-->
<!--        <li v-for="(obj,index) in ServiceList" v-on:mousemove="revolveImg(index,obj.developSrc)"  v-on:mouseleave="revolveBackImg(index,obj.src)" >-->
<!--          <img :src="obj.src" alt="" data-aos="fade-up"  class="rotatedModule"/>-->
<!--          <div>-->
<!--            <p data-aos="fade-up">{{ obj.identity }}</p>-->
<!--            <p v-html="obj.description" data-aos="fade-up"></p>-->
<!--          </div>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
    <solution data-aos="fade-up" style="padding-top: 20px;padding-bottom: 60px"></solution>
    <products data-aos="fade-up" style="margin-top: 40px"></products>


    <div class="section-eight" style="margin-top: 180px">
      <div class="bgImgBox" v-for="(obj, index) in featureList" :key="index">
        <div class="content">
          <div class="left" :style="{ padding: index== 1?'0 0 0 80px':'0 80px 0 0' }">
            <img :src="obj.src1" alt="" data-aos="fade-right"/>
            <div>
              <p data-aos="fade-right">{{ obj.feature }}</p>
              <p data-aos="fade-right">{{ obj.description }}</p>
            </div>
          </div>
          <div class="right" >
            <img :src="obj.src2" alt="" data-aos="fade-left"  />
          </div>
        </div>
      </div>
    </div>

    <aptitude data-aos="fade-up"></aptitude>
    <customer data-aos="fade-up"></customer>
    <min-news style="background-color: #f8f9fa" data-aos="fade-up"></min-news>


    <!--    <tryout-project></tryout-project>-->

<!--    <div class="section-ten">
      <p>更便捷从一键部署开始</p>
      <a href="#"><img src="../assets/images/icon-windows.png" alt="" /></a>
      <h5>Windows版</h5>
      <h6>最新版本：v3.0</h6>
      <h6>系统要求：Windows</h6>
    </div>-->
  </div>
</template>

<script>

import customer from './components/customer.vue';
import { gsap } from "gsap";
import {insertUserInfo} from "@/api/kms/userInfo";
import {Message} from "element-ui";
import solution from "@/views/components/solution.vue";

import aptitude from './components/aptitude.vue';
import products from './components/products.vue';
import minNews from './components/minNews.vue';
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import $ from 'jquery'

//第二屏的动画
// ScrollTrigger.created({
//   // 触发动画的元素
//   trigger : '.section-two',
//   //起始位置
//   start :'top,top',
//   //结束位置
//   end :'end,end',
//   markers : true
//
// })


export default {



  components:{
    customer,
    aptitude,
    solution,
    products,
    minNews

  },
  data() {
    return {
      showSwiperButton:false,

      swiperOptions: {
        // autoplay:true,
        // //设置环路

        // 如果需要分页器

        // 如果需要前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        slidesPerView : 1,
        initialSlide: 1,
        // clickable: true,
        // grabCursor:"true",
        // loop : true,
        speed: 1500,
        cubeEffect:{

        shadow: false,
          slideShadows: false,

           // shadowOffset: 20,
           // shadowScale: 0.94,
         },grid: {
          fill: 'column',
          rows: 1,
        },


      },
      //用户手机
      phone:'',
      //用户姓名
      userName:'',
      //所属单位
      affiliation:'',
      open:false,
      sOneContent1: true, //banner图中的内容区是否需要展示
      timer: "", //定时器变量
      active: true, //当前选中元素
      start: false, //定时器开关
      bannerUrl: "",
      bannerOne: require("../assets/images/bg_one.jpg"),
      bannerTwo: require("../assets/images/slide_2.jpg"),
      bFootList: [
        {
          title: "基于B/S架构",
          descrption: "浏览器直接访问，无须安装任何插件",
          src: require("../assets/images/home/icon-fea-jg.png"),
        },
        {
          title: "多全宗管理",
          descrption: "支持多全宗、跨全宗管理与利用",
          src: require("../assets/images/home/icon-fea-cc.png"),
        },
        {
          title: "分布式存储",
          descrption: "管理千万级级数据，提供分布式存储解决方案",
          src: require("../assets/images/home/icon-fea-wd.png"),
        },
        {
          title: "自定义门类",
          descrption: "支持任意门类设置、数据库字段设置",
          src: require("../assets/images/home/icon-fea-bd.png"),
        },
      ],
      tabControl: [
        {
          title: "档案管理平台",
          description:
            "数字档案信息化系统支持集成管理各门类数字档案资源，具备收集、元数据捕获、登 记、分类、编目、著录、存储、数字签名、检索、利用、鉴定、统计、处置、格式转换、命名、移交、审计、备份、灾难恢复、用户管理、权限管理等基本功能，为电子 档案的真实、完整、可用和安全提供首要保障，并达到灵活扩展、简单易用的基本要求。参照《电子档案管理系统通用功能要求》建设。",
          src: require("../assets/images/solution-img-1.jpg"),
          display: true,
          link:'#/product/manage'
        },
        {
          title: "档案四性检测",
          description:
            "新施行的《档案法》第三十七条规定“电子档案与传统载体档案具有同等效力，可以以电子形式作为凭证使用”。这从法律层面明确了电子档案的法律效力，" +
              "可以极大地促进档案工作逐步过渡到“单套制”“单轨制”管理模式。同时它又规定了电子档案具有法律效力的前提是“来源可靠、程序规范、要素合规”。要做到“来源可靠、" +
              "程序规范、要素合规”，保证电子档案的真实性、完整性、可用性和安全性（简称“四性”）是关键。为保证电子档案“四性”，依托信息管理环境，利用技术手段对其进行检测，" +
              "是保证电子档案“四性”的重要措施之一。",
          src: require("../assets/images/solution-img-2.jpg"),
          display: false,
          link:'#/product/use'
        },
        {
          title: "声像档案管理",
          description:
            "支持照片、视频、音频的导入，生成照片缩略图、视频自动截取封面。声像整理著录、人物信息标注、" +
              "元数据自动捕获、人脸智能检索照片、多人智能检索照片、元数据浏览、视频播放、以图搜索",
          src: require("../assets/images/solution-img-4.jpg"),
          display: false,
          link:'#/product/identification'
        },
        {
          title: "档案智能著录",
          description:
            "支持对已上传的电子文件的筛选、删除、修改功能； 支持对已上传的电子文件进行件/组信息（标题、时间、拍摄者、人物、地点、内容等）整理。",
          src: require("../assets/images/solution-img-5.jpeg"),
          display: false,
          link:'#/product/intelligence'
        },
      ],
      dataDesList: [
        {
          src: require("../assets/images/home/icon-data-num.png"),
          data: "1000万+",
          description: "档案数量",
        },
        {
          src: require("../assets/images/home/icon-data-custom.png"),
          data: "1000+",
          description: "使用客户",
        },
        {
          src: require("../assets/images/home/icon-data-staff.png"),
          data: "1万+",
          description: "企业员工",
        },
      ],
      attribute: ["使用部门多", "档案类目多", "超大文件多", "覆盖范围广"],
      ServiceList: [
        {
          src: require("../assets/images/home/icon-aqtx.png"),
          identity: "安全、可靠",
          description:
            "使用国产的安全算法、数据加密技术，提供严密的备份、恢复机制。",
          developSrc:require("../assets/images/home/icon-aqtx2.png")
        },
        {
          src: require("../assets/images/home/icon-dptfg.png"),
          identity: "专业、规范",
          description:
            "严格以国家档案建设规范为基础，并结合单位实际情况做个性化调整。可通过国家数字档案室评分标准",
          developSrc:require("../assets/images/home/icon-dptfg2.png"),
        },
        {
          src: require("../assets/images/home/icon-zjzyfw.png"),
          identity: "咨询服务",
          description:
            "多年行业从业者指导，为机关、企业提供档案管理深度解决方案与优质实践",
          developSrc:require("../assets/images/home/icon-zjzyfw2.png"),
        },
        {
          src: require("../assets/images//home/icon-ppjc.png"),
          identity: "稳定、智能",
          description:
            "系统采用分布式架构,结合行业未来发展趋势，融入了大数据分析、OCR、NLP自然语言处理、全文检索引擎。助力客户在档案分析、管理方面挖掘更大的价值。",
          developSrc:require("../assets/images//home/icon-ppjc2.png")
        },
      ],
      // identityList: [
      //   {
      //     src: require("../assets/images/user-pic2.png"),
      //     identity: "我是档案馆",
      //     description:
      //         "档案移交接收检查耗时？<br>多全宗管理不清晰？<br>如何满足档案业务评价要求？",
      //   },
      //   {
      //     src: require("../assets/images/user-pic3.jpg"),
      //     identity: "我是单位",
      //     description:
      //       "档案类目多且数据量大？<br>整理没有统一规范？<br>权限控制不到位？",
      //   },
      //   {
      //     src: require("../assets/images/user-pic1.jpg"),
      //     identity: "我是企业",
      //     description:
      //       "业务系统无法自动归档？<br>各部门兼职档案员移交档案不明确？<br>工程项目档案如何管理？",
      //   },
      //   {
      //     src: require("../assets/images/user-pic4.jpg"),
      //     identity: "我是学校",
      //     description:
      //       "档案类目多且数据量大？<br>整理没有统一规范？<br>权限控制不到位？",
      //   },
      //
      // ],
      featureList: [
        {
          src1: require("../assets/images/icon-power.png"),
          feature: "系统建设规范，权限控制缜密",
          description:
            "以国家档案建设规范为基础，包括档案元数据方案、档号编制规则、档案著录规则、档案管理规范等。支持角色、用户、权限自定义、三权分立。可控制用户能操作什么功能，能操作什么数据。",
          src2: require("../assets/images/insert-img3.jpg"),
        },
        {
          src1: require("../assets/images/icon-search.png"),
          feature: '自定义程度高，构建适用本单位的档案平台',
          description:
            '根据不同业务自定义档案类型模板: 财会模板、文书模板、人事模板、婚姻模板、基建工程模板等，可无限制定义。'+
            '根据档案实际管理情况，可自定义档号构成规则'+
            '自定义档案目录采集表单，通过托拉拽即可完成'
          ,
          src2: require("../assets/images/insert-img4.jpg"),
        },
        {
          src1: require("../assets/images/icon-easy.png"),
          feature: "自定义报表设计器，让档案统计不再固化",
          description:
            '档案类目有多少? 文件存储情况? 数量有多少？利用情况如何？都是档案管理者必掌握的情况。 '+
            '多维度可视化数据报表，既能跟踪每类档案的使用情况，也能全局掌握档案保管与利用状况。 '+
            '结合客户自身的管理场景，为管理人员设置不同的数据范围权限及报表访问权限'+
            '支持档案业务、档案系统服务监控数据可视化。为档案管理者、系统运维人员对系统的业务情况、运行情况提供可靠数据依据。'
          ,
          src2: require("../assets/images/insert-img5.jpg"),
        },
      ],
      logoList: [
        // require("../assets/images/logo/logo_al01.png"),
        // require("../assets/images/logo/logo_al02.png"),
        // require("../assets/images/logo/logo_al03.png"),
        // require("../assets/images/logo/logo_al04.png"),
        // require("../assets/images/logo/logo_al05.png"),
        // require("../assets/images/logo/logo_al06.png"),
        // require("../assets/images/logo/logo_al07.png"),
        // require("../assets/images/logo/logo_al08.png"),
        // require("../assets/images/logo/logo_al09.png"),
        // require("../assets/images/logo/logo_al10.png"),
        // require("../assets/images/logo/logo_al11.png"),
        // require("../assets/images/logo/logo_al12.png"),
        // require("../assets/images/logo/logo_al13.png"),
        // require("../assets/images/logo/logo_al14.png"),
        // require("../assets/images/logo/logo_al15.png"),
        // require("../assets/images/logo/logo_al16.png"),
      ],
      offerData:[
        {
          name: "档案综合管理系统（信创单机版）",
          system:"限制用户数。",
          price:"9万8"
        }, {
          name: "档案综合管理系统（信创网络版）",
          system:"不限用户。",
          price:"49万8"
        }, {
          name: "智慧档案管理平台（高校版）",
          system:"不限用户。",
          price:"49万8"
        }, {
          name: "数字档案信息化管理平台（企业版）",
          system:"不限用户。",
          price:"49万8"
        },{
          name: "服务器",
          system:"信创目录",
          price:"8万-10万"
        }, {
          name: "数据库",
          system:"人大金仓",
          price:"10万"
        }, {
          name: "数字化加工服务",
          system:"按照国家标准制作数字档案",
          price:"按档案情况"
        }
      ]
    };

  },
  methods: {
    showOpen(){
      this.open=true
    },
    cancel(){
      this.open = false;
    },
    slideChange() {
      //是否轮播
      this.active = !this.active;
    },
    slideShow() {
      let that = this; //setinterval中this指向不对
      that.timer = setInterval(function () {
        if (that.start) {
          that.active = !that.active;
          //切换背景图
          // if (!that.active) {
          //   that.bannerUrl = that.bannerTwo;
          // } else {
          //   that.bannerUrl = that.bannerOne;
          // }
        }
      }, 8000);
    },
    slideEnd() {
      clearInterval(this.timer);
    },
    pauseTimer() {
      this.start = !this.start;
    },
    tabShow(val) {
      this.tabControl.forEach((obj) => (obj.display = false));
      this.tabControl[val].display = true;
    },revolveImg(index,src){
      let target=window.document.getElementsByClassName("rotatedModule")[index]

                gsap.to(target,{ y:2,
                                      rotationY: 180,
                                     onComplete:()=>{
                                         target.src=src;
                                     },
                                    })
    },
  revolveBackImg(index,src){
    let target=window.document.getElementsByClassName("rotatedModule")[index]

    gsap.to(target,{ y:2,
      rotationY: -180,
      onComplete:()=>{
        target.src=src;
      },
    })
  },comimtForm(){

      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
       if(this.phone===''){

         this.$message({
           type: 'warning',
           message: '手机号不能为空'
         });
        return;
      }
      if(!reg_tel.test(this.phone)){

        this.$message({
          type: 'warning',
          message: '请输入正确格式的电话号码'
        });

      return;
      }
      if(this.userName===''){

        this.$message({
          type: 'warning',
          message: '姓名不能为空'
        });
        return
      }
      if(this.affiliation===''){
        this.$message({
          type: 'warning',
          message: '单位不能为空'
        });
        return;
      }
      let parm={phone: this.phone,userName:this.userName,affillation:this.affiliation};
      insertUserInfo(parm).then(response => {
        console.log('response',response)
        if(response.data.code=='200'){
          Message({
            message: '申请成功，我们的客户将在稍后与您进行联系',
            type: 'success'
          })
          this.cancel()
        }else {
          Message({
            message: response.data.msg,
            type: 'error'
          })
        }
      })



    }


  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {

    const mobile = new Array("iphone", "ipod", "ipad", "android", "mobile", "blackberry", "webos", "incognito", "webmate", "bada", "nokia", "lg", "ucweb", "skyfire");
    const current = navigator.userAgent.toLowerCase();

    for (let i=0; i<mobile.length; i++){
      if(current.indexOf( mobile[i])!=-1){
        location.href = 'http://www.sxheqi.com:8891';
        break;
      }


    }


    this.bannerUrl = this.bannerOne;
    this.slideShow();
  },
  destroyed() {
    this.slideEnd();
    window.removeEventListener("scroll", this.handleScroll);
  }


};
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?3cf590d7972e5f00e69a95f8be65a94f";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

</script>

<style src="../assets/index.css" scoped></style>
<style scoped>
/* 移入移除元素开关 */
.open {
  display: block !important;
}

/* 当前选中元素开关 */
.active {
  display: block !important;
}
.swiper-Box{

//margin-left:20% ;
  width: 100%;
  height: 100%;
}
.swiper-slide-box{
  height: 100%;
  width: 100%;
  background-image: url("@/assets/images/lunbo/index1.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.swiper-button-prev,.swiper-button-next{
  width: 100px;
  height: 100px;
  background: none;
  text-align: center;
  line-height: 100px;
  background-color: rgba(0,0,0,0.2);
  border-radius:10% ;
}

.free-use{
  //position: relative;
  //display: inline-block;
  left: 45%;
  top: 50%;
  //background-color: rgb(31, 93, 234);
  border: rgb(31, 93, 234);

  border-radius: 5px;
  width: 162px;
  height: 50px;
  line-height: 50px;
  //z-index: 99999999;

}
.free-use{

}
</style>
