<template>
  <div>

      <div class="section-one" id="section-one"  :style="{ backgroundImage: `url(${backgroundUrl})` }">
      <div class="content">
        <h5>{{ companyName }}</h5>
        <h1>{{title}}</h1>
        <h6>{{description}}</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colorWord:{
      required: false,
      type: String,
      default: "#fff"

    },
    companyName: {
      required: true,
      type: String,
      default: "禾祺科技"

    },
    title: {
      required: true,
      type: String,
      default: "携手共进 合作共赢",
    },
    description: {
      required: true,
      type: String,
      default: "全国各省市诚招合伙人，携手共促档案行业新时代",

    },
    backgroundUrl:{

      required:false,
      type:String,
      default:require('@/assets/images/product_bg.jpg')
    }
  },

  mounted() {
    // console.log('this.backgroundUrl',this.backgroundUrl)
    // console.log('require(this.backgroundUrl)',require(this.backgroundUrl))
    // console.log('this.default',this.default)

    // document.getElementById("section-one").style.backgroundImage='url('+this.backgroundUrl+')'


    console.log(require('@/assets/images/nav/pexels-photo-7446584.png'))
  }
};
</script>

<style lang="scss" scoped>
.section-one {
  height: 494px;
  //background-image: url("@/assets/images/product_bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  // -webkit-mask : -webkit-gradient(linear, left top, right bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
  color: #fff;
}

.section-one .content {
  color: #fff;
  text-align: center;
  padding: 140px 0 0;
}

.section-one .content h5 {
  font-size: 30px;
  margin-bottom: 25px;
  font-weight: 400;
}

.section-one .content h1 {
  font-size: 42px;
  margin-bottom: 25px;
  font-weight: 500;
}

.section-one .content h6 {
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .section-one {
    height: 392px;
  }
  .section-one .content {
    padding-top: 100px;
  }
}
@media (max-width: 992px) {
  .section-one .content {
    padding-top: 100px;
  }
  .section-one .content h5 {
    font-size: 26px;
  }
  .section-one .content h1 {
    font-size: 38px;
  }
  .section-one .content h6 {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .section-one {
    height: 300px;
  }
  .section-one .content {
    padding-top: 60px;
  }
  .section-one .content h5 {
    font-size: 22px;
  }
  .section-one .content h1 {
    font-size: 32px;
  }
  .section-one .content h6 {
    font-size: 16px;
  }
}
@media (max-width: 442px) {
  .section-one {
    height: 260px;
  }
  .section-one .content {
    padding-top: 80px;
  }
  .section-one .content h5 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .section-one .content h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .section-one .content h6 {
    font-size: 14px;
  }
}
</style>