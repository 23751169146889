import request from "@/api/request";

export function getNoticeList() {
    return request({
        url: '/system/notice/listEnablePart' ,
        method: 'get'
    })
}
export function getInformation(query) {
    return request({
        url: '/system/notice/getInformation' ,
        method: 'get',
        params:query
    })
}
export function getNoticeHot() {
    return request({
        url: '/system/notice/listHot' ,
        method: 'get'
    })
}
export function getNoticeById(id) {
    return request({
        url: '/system/notice/' +id,
        method: 'get'
    })
}
export function  getNewsIndex(query) {
    return request({
        url: '/system/notice/newsIndex' ,
        method: 'get',
        params: query
    })
}