<template>
  <div>
      <div   class="mian-box">

      <el-row type="flex" justify="center" >
        <el-col :span="10"  >
          <el-card shadow="hover" style="padding-bottom: 100px">
            <el-row>
             <el-col type="flex" justify="">

               <span class="title">{{fromdata.title}}</span>
             </el-col>
            </el-row>
            <el-row>
              <div class="information" style="float: left">
                <div class="department grayFont" >{{fromdata.department}}</div>
                <div class="time grayFont" >发布于 {{fromdata.date}}</div>
              </div>
<!--              <div style="float: right">  <el-button type="primary" round style="background-color: #1f5dea;margin-top: 10px;border: #1f5dea" >申请职位</el-button>-->
<!--              </div>-->
            </el-row>
            <el-row class="list-box">
              <el-col class="job-description"  >
                        <div class="job-description-title subheading">职位描述</div>
<!--                         <p> 职位描述 </p>-->
                         <el-row v-html="fromdata.jobResponsibilities"> </el-row>


              </el-col>
              <el-col class="job-requirements" style="margin-top: 20px"  v-show="fromdata.jobRequirements">
                <p> 任职要求 </p>
                <el-row v-html="fromdata.jobRequirements"> </el-row>

              </el-col>

              <el-col class="job-requirements" style="margin-top: 20px"  v-show="fromdata.personnelRequirements">
                <p> 人员需求 </p>
                <el-row v-html="fromdata.personnelRequirements"> </el-row>

              </el-col>



            </el-row>
            <el-row class="bottom-information" >
              <el-col class="job-description" style="margin-top: 20px">
                <div class="job-description-title subheading" >职位信息</div>
              </el-col>

            </el-row>
            <el-row type="flex" style="margin-top: 20px" >
              <el-col class="grayFont">发布日期</el-col>
              <el-col class="grayFont">职位类别</el-col>
              <el-col class="grayFont">所属部门</el-col>
              <el-col class="grayFont">职位名称</el-col>
            </el-row>
            <el-row type="flex" style="margin-top: 20px" >
              <el-col>{{fromdata.date}}</el-col>
              <el-col>{{fromdata.posts}}</el-col>
              <el-col>{{fromdata.department}}</el-col>
              <el-col>{{fromdata.title}}</el-col>
            </el-row>

          </el-card>
        </el-col>
      </el-row>
      </div>




  </div>

</template>

<script>
export default {
  name: "recruit",
  created() {
    this.initData()

  },
  data(){
        return {
          id:'',
          fromdata:{
            id:1,
            worry:true,

            title:'档案整理人员',
            department:'市场部',
            posts:'普通员工',
            date:'2023-11-13',
            descriptions:['档案','|','档案整理','|','档案数字化'],
            address:'[太原·迎泽区·迎泽大街]',
            jobResponsibilities:`
工作内容:面向政府机关、企事业单位，派驻现场服务，对各门类档案进行鉴定、分类，按照标准进行规范操作，如排序、编码、著录、扫描、装订、打印、上架等。<br>人员要求:20周岁以上，熟悉办公软件，做事积极主动、有思想，有责任心，学习能力强，上手快，有同业经验者优先考虑。<br>薪资待遇:2300－5000不等，根据个人能力匹配，五险、奖金、餐补、宿舍等。<br>工作地点:项目1：太原小店区南中环、  项目2：长风大街108号   项目3：山西转型综合改革示范区管委会             <br>工作时间:08:00－12:00；14:00－18:00<br>招聘：寒假工，实习生 薪资面议           `,
            jobRequirements:``,
            personnelRequirements:``


          },

          dataList: [{
            id:1,
            worry:true,
            department:'市场部',
            title:'档案整理人员',
            posts:'普通员工',
            date:'2023-11-13',
            descriptions:['档案','|','档案整理','|','档案数字化'],
            address:'[太原·迎泽区·迎泽大街]',
            jobResponsibilities:`
工作内容:面向政府机关、企事业单位，派驻现场服务，对各门类档案进行鉴定、分类，按照标准进行规范操作，如排序、编码、著录、扫描、装订、打印、上架等。<br>人员要求:20周岁以上，熟悉办公软件，做事积极主动、有思想，有责任心，学习能力强，上手快，有同业经验者优先考虑。<br>薪资待遇:2300－5000不等，根据个人能力匹配，五险、奖金、餐补、宿舍等。<br>工作地点:项目1：太原小店区南中环、  项目2：长风大街108号   项目3：山西转型综合改革示范区管委会             <br>工作时间:08:00－12:00；14:00－18:00<br>招聘：寒假工，实习生 薪资面议           `,
            jobRequirements:``,
            personnelRequirements:``


          },
            {
              id:2,
              worry:true,
              title:'项目经理',
              posts:'项目部经理',
              department:'项目部',
              date:'2023-11-13',
              descriptions:['工程档案'],
              address:'[太原·迎泽区·迎泽大街]',
              jobResponsibilities:`1、对工程项目档案进行规范化管理，对各参建单位、监理单位等设计工程项目方案设定标准与规范，保证资料收集真实、完整，齐全；<br>2、对工程项目档案整体进度、质量、人员整体管理，保证工程档案及时归档整理，满足项目档案验收要求；<br>3、年龄55岁以下，可以保证长时间，定期或者不定期驻项目所在地工作，具备工程项目档案管理经验，有电力行业者优先；<br>4、待遇面谈<br>工作地点:太原小店区南中环、迎泽区体育馆及迎泽大街附近   山西转型综合改革示范区管委会附近,`,              jobRequirements:``,
              personnelRequirements:``
            },
            {
              id:3,
              worry:true,
              title:'档案整理专员',
              department:'项目部',
              posts:'普通员工',
              date:'2023-11-13',
              descriptions:['档案整理','|','资料','|','数字化','|','内勤','|','服务'],
              address:'[太原·小店区·学府街]',
              jobResponsibilities:`工作周期：3个月<br>每周工期：5天及以上<br>工作时间：08:00-18:00<br>工作时段：按单安排时间<br>结算方式：月结<br>招聘截止时间：2024-01-27<br>工作内容:面向政府机关、企事业单位，派驻现场服务，对各门类档案进行鉴定、分类，按照标准进行规范操作，如排序、编码、著录、扫描、装订、打印、上架等。<br>人员要求:20周岁以上，熟悉办公软件，做事积极主动、有思想，有责任心，学习能力强，上手快，有同业经验者优先考虑。<br>招聘：暑期工，有意者可以面议<br>薪资待遇:2000－5000不等，根据个人能力匹配。<br>工作地点:项目1：太原小店区南中环   项目2：迎泽区体育馆及迎泽大街附近   项目3：南内环青龙电脑城附近    项目4：小店区长风大街108号  项目5：山西转型综合改革示范区管委会             <br>工作时间:08:00－12:00；14:00－18:00`,
              jobRequirements:``,
              personnelRequirements:``
            },
            {
              id:4,
              worry:true,
              title:'行政人事主管',
              department:'项目部',
              posts:'部门主管',
              date:'2023-11-13',
              descriptions:['管理体系建设优化','|','流程制度定制/优化','|','企业文化建设'],
              address:'[太原·迎泽区·迎泽大街]',
              jobResponsibilities:`<br>1.人员管理：负责招聘、选拔、培训、考核公司员工，以及制定和实施员工激励措施，提高员工满意度和绩效。<br>2.流程管理：优化和改进公司内部流程，提高工作效率，包括但不限于行政管理、人力资源管理和财务管理等方面。<br>3.档案管理：管理公司档案，包括员工档案、合同档案、财务档案等，保证档案的完整性和安全性。<br>4.其他行政工作：负责公司环境管理、固定资产管理、物资采购、车辆管理、接待来访等工作。<br>5.与上级领导协调配合：协助上级领导制定公司战略计划，并参与公司决策，保证工作的高效性和准确性。`,
              jobRequirements:`<br>1.具备优秀的时间管理和组织能力，能够合理安排工作进度，保证工作质量和标准。<br>2.具备良好的沟通能力和协调能力，能够有效地与员工和管理层沟通，解决问题。<br>3.具备专业的行政和人力资源管理知识和技能，能够熟练运用相关软件和工具。<br>4.具备高度的责任心和工作热情，能够积极应对工作中的挑战和压力。具备良好的学习能力和适应能力，能够不断学习和适应公司的发展需求`,
              personnelRequirements:`<br>1.本科以上学历，30－40之间，男女不限；<br>2.有5年以上工作经验，精通计算机相关应用；<br>3.有责任心，有统筹工作能力；<br>4.能根据公司战略目标制定相关政策。`
            },
            // {   id:5,
            //   worry:false,
            //   title:'java开发工程师',
            //   department:'开发部',
            //   posts:'普通员工',
            //   date:'2023-11-13',
            //   descriptions:['java开发'],
            //   address:'[湖南长沙]',
            //   jobResponsibilities:`
            //                 <p>  1、负责IT部门信息化相关系统的设计和开发</p>
            //                 <p>2、领导和协同开发团队准时、优质的完成全部工作</p>
            //                 <p>3、参与需求评审、完成部分架构设计，组织评审</p>
            //                 <p> 4、攻克技术难题、积极引入先进技术执行和落地</p>`,
            //
            //   jobRequirements:`            <p>1、扎实的计算机基础（操作系统，数据结构与算法）和软件开发能力；&nbsp;</p>
            //     <p>2.熟悉掌握常用的Java类库及开源框架，如多线程、并发处理、I/O与网络通讯、Spring等，掌握Java虚拟机原理，有JVM分析及调优的经验；</p>
            //     <p>3.精通&nbsp;MySQL&nbsp;应用开发，熟悉数据库原理和常用性能优化技术，了解分布式数据库、NoSQL、MQ的原理、使用场景以及限制；&nbsp;</p>
            //     <p>4.有高性能、大数据系统业务处理经验；</p>
            //     <p>5.研究过&nbsp;http/tcp&nbsp;协议、搜索引擎、缓存、jvm、序列化、nio、RPC框架等，有相关实践经验；&nbsp;</p>
            //     <p>6.擅长使用开源框架，在某个领域内对开源框架有一定的审美，做过选型研究，解读过部分或者全部源码，掌握实现原理。&nbsp;具备以下条件优先有较强的逻辑思维能力，善于分析、归纳、解决问题，有独立或带队进行项目开发能力者有相关领域建设经验并总能形成方法论</p>
            //     <p>7、有IT信息化经验优先</p>`,
            //   personnelRequirements:``
            // },
            {
              id:6,
              worry:true,
              title:'行业客户经理',
              department:'市场部',
              posts:'普通员工',
              date:'2023-11-13',
              descriptions:['政府/社会团体','|','对企业/机构（To B）销售经验','|','互联网/科技'],
              address:'[太原·小店区·学府街]',
              jobResponsibilities:`<br>1.市场调研与分析:·定期收集并分析市场趋势、竞品信息和客户需求，为销售策略提供数据支持。深入研究档案管理软件行业动态，为公司产品研发或优化提供建议。<br>2.销售目标的制定与达成:根据公司战略和市场状况，制定个人及团队的年度、季度、月度销售目标。按照销售计划，有效开展销售活动，确保销售业绩的完成。<br>3.客户关系管理:建立并维护与客户的长期合作关系，了解客户的需求与期望，确保客户满意度。定期对客户进行回访，深入了解客户的反馈，及时处理问题，提升客户忠诚度。<br>4.产品知识与培训:深入理解公司档案管理软件的功能、优势及特点，为客户提供专业的产品咨询。针对客户需求，为客户提供个性化的解决方案，促进软件的销售。<br>5.销售策略与方案制定:根据市场状况和客户需求，制定并调整销售策略和推广方案。策划并执行各类线上线下销售活动，提高产品的市场占有率。<br>6.合作伙伴关系建设:积极寻找并建立与相关行业的合作伙伴关系，扩大销售渠道。与其他部门如技术、客服等紧密合作，共同为客户提供优质服务。<br>7.团队协同与支持:在销售团队中发挥积极作用，协同完成团队的销售目标。对新入职的团队成员提供必要的支持和指导，确保团队整体的销售能力。`,
              jobRequirements:` <br>1.效率与进度:能适应长期出差，合理安排工作时间，提高工作效率，确保各项工作按时完成。及时汇报工作进度，确保销售活动的顺利进行。<br>2.质量与标准:·严格遵守公司的销售流程和服务标准，确保提供给客户的服务质量。对销售过程中的问题及时发现并解决，持续优化工作流程。<br>3.态度与心态:保持良好的工作态度，积极面对挑战和压力，始终保持积极向上的心态。与同事保持良好的沟通与合作，共同营造积极的工作氛围。<br>4.专业能力与技能:·不断学习档案管理软件行业的最新知识和技术，提高个人的专业水平。掌握基本的商务谈判技巧和客户关系管理技巧，提升个人和团队的业绩。<br>5、有政府行业项目销售经验者优先考虑。`,
              personnelRequirements:``,

            },
            {
              id:7,
              worry:true,
              title:'项目售前支持',
              department:'市场部',
              posts:'普通员工',
              date:'2023-11-13',
              descriptions:['售前技术支持经验','|','可适应出差','|','计算机/通信相关专业'],
              address:'[太原·小店区·学府街]',
              jobResponsibilities:`<br>1、配合销售对项目全程跟踪，交流汇报、汇报材料、方案编撰、产品演示、招投标等工作；<br>2、\t公司产品的宣传推广、内部培训、基础资料准备等；<br>3、\t市场调研分析、项目分析、业务统计分析、竞品分析等；<br>4、\t售前方案整理和包装，行业解决方案设计。`,
              jobRequirements:` <br>1、本科以上学历，计算机相关专业，有经验者可以适当放宽；<br>2、3年以上软件售前工作经验，熟练使用OFFICE系列办公软件及常用办公工具；<br>3、工作主动，有很强的团队意识；责任心强；能适应出差<br>4、有良好的沟通能力和表达能力，能够与客户建立良好的信任关系；<br>5、有良好的学习能力和创新能力，能够跟进最新的技术和趋势；<br>6、有档案行业软件的项目实施或管理经验者优先。`,
              personnelRequirements:``
            },
          ]



        }
  },methods:{
    initData(){
                this.dataList.forEach(item=>{

                  if(item.id==this.$route.query.id){

                    this.fromdata=item

                    return
                  }
                })
    }
  }


}
</script>

<style scoped>
  .mian-box{

    width: 100%;
    height: 100%;
    background-color: #f4f4f6;
    //text-align: center;


    margin-top: 68px;
    padding-top: 40px;
    padding-bottom: 5%;
    //margin-bottom: 0;

    //background-size: contain;
    //text-align: center;
    //height: 500px;

  }
/**
title 标题
 */
  .mian-box .title{

    font-weight: 500;
    color: #141933;font-weight: 400;
    font-size: 24px;

  }
  /**
 浅色字体
 */
  .grayFont{
    color: #989cb2;
    font-size: 14px;
    font-weight: 400;
  }

  .bottom{
    line-height: 1.5;
    font-family: custom-font, -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Microsoft YaHei', 'Lantinghei SC', 'Helvetica Neue', Arial, 'Open Sans', 'Hiragino Sans GB', sans-serif;
    color: #141933;
    font-size: 14px;
    font-weight: 400;
  }
  /**
  副标题
*/
  .subheading{
    color: #141933;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }
  .mian-box .information{
    margin-top: 10px;
  }
  .mian-box .information .time{
    margin-top: 10px;
  }
  .mian-box  .list-box{
    margin-top: 32px;
    border-top: 1px solid #e6e8f2;
  }
  .mian-box  .list-box .job-description{
    margin-top: 10px;

  }
  .mian-box  .list-box .job-description .job-description-title{
    margin-bottom: 20px;

  }
  .mian-box  .list-box .job-description >p{
    line-height: 1.5;
    font-family: custom-font, -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Microsoft YaHei', 'Lantinghei SC', 'Helvetica Neue', Arial, 'Open Sans', 'Hiragino Sans GB', sans-serif;
    color: #141933;
    font-size: 14px;
    font-weight: 400;
  }
  .mian-box  .list-box .job-requirements >p{
    line-height: 1.5;
    font-family: custom-font, -apple-system, BlinkMacSystemFont, 'PingFang SC', 'Microsoft YaHei', 'Lantinghei SC', 'Helvetica Neue', Arial, 'Open Sans', 'Hiragino Sans GB', sans-serif;
    color: #141933;
    font-size: 14px;
    font-weight: 400;
  }
  .mian-box  .bottom-information{
    margin-top: 32px;
    border-top: 1px solid #e6e8f2;
  }





</style>