<template>

  <div>


    <section id="view-contact" >
      <div class="box">
        <p class="title ">联系我们，让档案管理更高效</p>
        <p class="sub_title ">提交信息即可免费体验，商务顾问将与您沟通定制解决方案</p>


        <el-button type="primary" round style="background-color: #1f5dea;margin-top: 10px;border: #1f5dea" @click="showOpen">马上预约体验</el-button>
      </div>
    </section>
    <el-dialog title="请填写联系方式,我们为您提供免费试用的账号" :visible.sync="open"  @close="cancel"
               width="600px"  center   append-to-body >

      <div><span >姓名</span><el-input v-model="userName" placeholder="请输入内容" ></el-input></div>
      <div><span>所属单位</span><el-input v-model="affiliation"  placeholder="请输入内容"></el-input></div>
      <div><span>电话号码</span><el-input  v-model="phone" placeholder="电话号码"></el-input></div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="comimtForm">确 定</el-button>

        <el-button @click="cancel">取 消</el-button>
      </div>

    </el-dialog>
  </div>

</template>

<script>
import {insertUserInfo} from "@/api/kms/userInfo";
import {Message} from "element-ui";

export default {
  data() {
  return   {
    open:false,
    phone:'',
    //用户姓名
    userName:'',
    //所属单位
    affiliation:'',
  }
  },methods:{
    showOpen(){
      this.open=true
    },
    cancel(){
      this.open = false;
    },
    comimtForm(){

      let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if(this.phone===''){

        this.$message({
          type: 'warning',
          message: '手机号不能为空'
        });
        return;
      }
      if(!reg_tel.test(this.phone)){

        this.$message({
          type: 'warning',
          message: '请输入正确格式的电话号码'
        });

        return;
      }
      if(this.userName===''){

        this.$message({
          type: 'warning',
          message: '姓名不能为空'
        });
        return
      }
      if(this.affiliation===''){
        this.$message({
          type: 'warning',
          message: '单位不能为空'
        });
        return;
      }
      let parm={phone: this.phone,userName:this.userName,affillation:this.affiliation};
      insertUserInfo(parm).then(response => {
        console.log('response',response)
        if(response.data.code=='200'){
          Message({
            message: '申请成功，我们的客户将在稍后与您进行联系',
            type: 'success'
          })
          this.cancel()
        }else {
          Message({
            message: response.data.msg,
            type: 'error'
          })
        }
      })
    }
  }

}
</script>

<style scoped>
  #view-contact{
    //background-image: url("~@/assets/images/bg_one.jpg");
    background-color: #0f9dfc;
    width: 100%;
    height: 336px;
    font-family: "Montserrat Light", "PingFang SC", "Hiragino Sans GB", "Hiragino Sans GB W3", "Source Han Sans Light", "Source Han Sans SC Light", "Noto Sans CJK Sc Thin", "Microsoft Jhenghei", "Avenir", "Helvetica Neue", Arial, sans-serif;
    padding-top: 80px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

  }
  #view-contact>p{
    ont-family: "Montserrat Ultra Light", "Montserrat Light", "PingFang SC", "Hiragino Sans GB", "Hiragino Sans GB W3", "Source Han Sans Light", "Source Han Sans SC Light", "Noto Sans CJK Sc Thin", "Microsoft Jhenghei", "Avenir", "Helvetica Neue", Arial, sans-serif !important;
  }
  .title{
    font-size: 32px;
    line-height: 1.1;
    color: #ffffff;

  }
  .sub_title{
    margin-top: 16px;
    font-size: 16px;
    line-height: 1.1;
    color: #ffffff;
  }
  .box{
    width: 100%;
    text-align: center;
  }



</style>