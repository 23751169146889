<template>
  <div>
    <div class="section-night">
      <h2 data-aos="fade-up">我们的客户</h2>

      <ul >
        <li v-for="(item, index) in companyList1" :key="index" data-aos="fade-up">
          <router-link to="/index">

              <img  class="imgtop" :src="item.url" alt=""  >


          </router-link>

        </li>
      </ul>
      <ul>


        <li v-for="(item, index) in companyList" :key="index" :style="{marginRight:index }" data-aos="fade-up">

          <router-link to="/index" data-aos="fade-up" class="bottom-box">
           <img :src="item.url" alt="" >
            <span class="companyName" :title="item.name" data-aos="fade-up">{{item.name}}</span>
          </router-link>
        </li>

      </ul>
<!--      <h3>开始体验我们的服务</h3>
      <p>现在开始尝试更简单高效的工作方式</p>
      <button
      >
      &lt;!&ndash; <button
        onclick="window.open('#')"
      > &ndash;&gt;
        开始体验
      </button>-->
    </div>
  </div>
</template>

<script>
import Archives from '@/assets/images/logo_achrive.png'
export default {
  data() {
    return {
      govermentLogo:require('@/assets/images/logo-government.png'),
      archivesLogo:Archives,
      companyList: [
        // {url:require('@/assets/images/khlog/山西省档案馆.png'),name:'山西省档案馆'},
        {url:require('@/assets/images/logo_achrive.png'),name:'太原市档案馆'},
        {url:require('@/assets/images/logo_achrive.png'),name:'运城市档案馆'},
        {url:require('@/assets/images/logo_achrive.png'),name:'永济市档案馆'},
        {url:require('@/assets/images/logo_achrive.png'),name:'万荣县档案馆'},
        {url:require('@/assets/images/logo_achrive.png'),name:'平城区卫体局'},
        {url:require('@/assets/images/logo_achrive.png'),name:'永济市卫体局'},
        {url:require('@/assets/images/logo_achrive.png'),name:'永济市国家税局'},
        {url:require('@/assets/images/logo-government.png'),name:'永济市行政审批管理局'},
        {url:require('@/assets/images/logo-government.png'),name:'中共山西省纪律检查委员会'} ,
        {url:require('@/assets/images/logo-government.png'),name:'中共山西省委政法委员会'},
        {url:require('@/assets/images/logo-government.png'),name:'稷山县人民法院'},
        {url:require('@/assets/images/logo-government.png'),name:'稷山县人民检察院'},
        {url:require('@/assets/images/logo-government.png'),name:'平顺县人民检察院'},
        {url:require('@/assets/images/logo-government.png'),name:'盐湖区人民检察院'},
        // {url:require('@/assets/images/logo-government.png'),name:'山西省法学会'},
        // {url:require('@/assets/images/logo-government.png'),name:'山西省人民政府外事办公室'},
        {url:require('@/assets/images/logo-government.png'),name:'委驻山西省卫计委纪检组'} ,
        {url:require('@/assets/images/logo-government.png'),name:'纪委驻山西省住建厅纪检组'} ,
        // {url:require('@/assets/images/logo-government.png'),name:'山西省纪委驻山西省水利厅纪检组'} ,
        {url:require('@/assets/images/logo-government.png'),name:'纪委驻山西行政审批局纪检组'} ,
        // {url:require('@/assets/images/logo-government.png'),name:'山西省自然资源厅阳泉开发区土地分局'},
        // {url:require('@/assets/images/logo-government.png'),name:'太原市公安局'} ,
        {url:require('@/assets/images/logo-government.png'),name:'山西省自然资源厅'} ,
        {url:require('@/assets/images/logo-government.png'),name:'临汾开发区土地分局'},
        // {url:require('@/assets/images/logo-government.png'),name:'山西博物院'} ,
        {url:require('@/assets/images/logo-government.png'),name:'自然资源厅侯马土地分局'},
        // {url:require('@/assets/images/logo-government.png'),name: '山西省农业农村厅' },
        {url:require('@/assets/images/logo-government.png'),name:'自然资源厅绛县土地分局'},
        // {url:require('@/assets/images/logo-government.png'),name:'山西省乡村振兴局'} ,
        // {url:require('@/assets/images/logo-government.png'),name:'山西出版传媒集团'},
        // {url:require('@/assets/images/logo-government.png'),name:'太原市人民代表大会常务委员会办公室'} ,
        // {url:require('@/assets/images/logo-government.png'),name:'山西交控养护集团'},
        // {url:require('@/assets/images/logo-government.png'),name:'山西省残疾人联合会'} ,
        {url:require('@/assets/images/logo-government.png'),name: '太长高速公路有限责任公司'},
        // {url:require('@/assets/images/logo-government.png'),name:'山西省市场监督管理局'} ,
        // {url:require('@/assets/images/logo-government.png'),name:'太原市市场监督管理局'} ,
        {url:require('@/assets/images/logo-government.png'),name:'长治市第三中学校'},
        {url:require('@/assets/images/logo-government.png'),name:'长治市特殊教育学校'},
        // {url:require('@/assets/images/logo-government.png'),name:'山西省人民防空办公室'} ,
        // {url:require('@/assets/images/logo-government.png'),name:'临汾职业技术学院'},
        {url:require('@/assets/images/logo-government.png'),name:'太原市人民防空办公室'} ,
        {url:require('@/assets/images/logo-government.png'),name:'长治市烟草公司'},
        {url:require('@/assets/images/logo-government.png'),name:'太原市公安局'},
        {url:require('@/assets/images/logo-government.png'),name:'山西省人民政府外事办公室'},


      ],
      //已经找到图片的
      companyList1:[
        {url:require('@/assets/images/khlog/山西省档案馆.png'),name:'山西省档案馆'},
        // {url:require('@/assets/images/khlog/稷山县人民检察院.png'),name:'稷山县人民检察院'},
        // {url:require('@/assets/images/khlog/山西省平顺县人民检察院.png'),name:'平顺县人民检察院'},
        // {url:require('@/assets/images/khlog/盐湖区人民法院.png'),name:'盐湖区人民检察院'},
        {url:require('@/assets/images/khlog/山西省法学会.png'),name:'山西省法学会'},
        // {url:require('@/assets/images/khlog/山西省人民政府外事办公室.png'),name:'人民政府外事办公室'},

        {url:require('@/assets/images/khlog/省纪委驻山西省水利局.png'),name:'纪委驻山西省水利厅纪检组'} ,

        {url:require('@/assets/images/khlog/山西省自然开发局阳泉分局.png'),name:'自然资源厅阳泉土地分局'},
        // {url:require('@/assets/images/khlog/太原市公安局.png'),name:'太原市公安局'} ,
        {url:require('@/assets/images/khlog/太原市市场监督管理局.png'),name:'太原市市场监督管理局'} ,
        {url:require('@/assets/images/khlog/山西省自然资源厅.png'),name:'山西省自然资源厅'} ,

        {url:require('@/assets/images/khlog/山西博物院.png'),name:'山西博物院'} ,

        {url:require('@/assets/images/khlog/山西省农业农村厅.png'),name: '山西省农业农村厅' },

        {url:require('@/assets/images/khlog/山西省乡村振兴局.png'),name:'山西省乡村振兴局'} ,
        {url:require('@/assets/images/khlog/山西出版传媒集团.png'),name:'山西出版传媒集团'},
        {url:require('@/assets/images/khlog/太原市人民代表大会常委会.png'),name:'太原人民常务委员会办公室'} ,
        {url:require('@/assets/images/khlog/山西交通养护公司.png'),name:'山西交控养护集团'},
        {url:require('@/assets/images/khlog/山西省残疾人联合.png'),name:'山西省残疾人联合会'} ,

        {url:require('@/assets/images/khlog/山西市场监督管理局.png'),name:'山西省市场监督管理局'} ,

        // {url:require('@/assets/images/khlog/长治市第三中学.png'),name:'长治市第三中学校'},

        {url:require('@/assets/images/khlog/山西省防空办公室.png'),name:'山西省人民防空办公室'} ,
        {url:require('@/assets/images/khlog/临汾职业技术学院.png'),name:'临汾职业技术学院'},


       ]

    };
  },
};
</script>

<style lang="scss" scoped>
.section-night {
  padding: 80px 0 60px;
  position: relative;
  text-align: center;
  background: #f5f8fd;
}

.section-night h2 {
  font-size: 38px;
  color: #0f9dfc;
  text-align: center;
  font-weight: 400;
  margin-bottom: 80px;
}

.section-night ul {
  display: flex;
  width: 880px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.section-night ul li {

  width: 200px;
  margin-bottom: 40px;
  vertical-align: middle;
  text-align: center;
}
.section-night ul li a{
  display: flex;
  align-items: center;
}

.section-night ul li img {
  width: 30px;
  height: 30px;
  display: inline-block;
}
.section-night ul li span{
  display: inline-block;
  width: auto;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.section-night h3 {
  margin-top: 130px;
  color: #333;
  font-size: 30px;
  font-weight: 400;
}

.section-night p {
  margin: 20px 0 40px;
  color: #555;
  font-size: 18px;
}

.section-night button {
  width: 140px;
  border: none;
  background: #0f9dfc;
  color: #fff;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  box-shadow: 4px 4px 6px rgba(88, 187, 252, 0.6);
}

@media (max-width:768px) {
    .section-night{
		padding: 40px 0 30px;


	}
	.section-night h2{
		margin-bottom: 20px;
		font-size: 18px;
	}
	.section-night ul{
		width: 100%;

	}
	.section-night ul li{
    width: 40%;
		margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;



	}
	.section-night ul li img{

    margin: 0 auto;
		max-width: 120px;
	}
	.section-night h3{
		margin-top: 0;
		font-size: 18px;
	}
	.section-night p{
		font-size: 14px;
		margin: 10px auto 15px;
	}

}
.toplist{
  display: flex;
  width: 800px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-around;
}
.toplist li{
  margin-bottom: 20px;
  height: 80px;
}
.section-night ul li a{
  //display: inline-block;
  width: 200px;
  height:50px;
  overflow: hidden;
  background: #ffffff;
}
.section-night ul li .imgtop{
  margin: 0 auto;
  width: 160px;
  height:40px;


}
.companyName{

   font-size: 12px;
  width: auto;
}
.bottom-box{
  padding-left:20px ;
}
</style>