<template>
	<div>
		<div class="section-one">
			<div class="banner">
				<h2>禾祺科技 档案管理专家</h2>
			</div>
			<div class="tab">
				<div class="top">
					<ul>
						<li :class="{current:obj.display}" v-for="(obj,index) in List" @click="display(index)"><img :src="obj.src" alt="">{{obj.title}}</li>
					</ul>
				</div>
				<div class="bottom">
					<div class="one" v-show="this.List[0].display">
						<p>
							<span>山西禾祺科技有限公司</span>（以下简称“公司”或“禾祺科技”）创始于2013年4月。经过多年发展,已经成为档案行业
							<span>专业解决方案</span>服务商,禾祺科技专注为各地的单位提供集档案管理咨询、档案整理、档案
							数字化加工;智慧感知档案库房建设;数字档案馆(室)建设;档案监理;数据保护、档案托管、档案管理系统研发、档
							案信息化等为一体的专业化档案服务。
						</p>
						<p>
							禾祺科技通过多年参与信息化建设服务的经验,具有一套优良的项目管理和质量监督体系,拥有一支稳定、
							高效、创新、激情和强烈责任感的技术研发和项目实施队伍,具有专业的档案结构信息化处理运营能力、
							数字化加工流程系统和档案信息类管理软件的研发能力,在档案信息处理、数字档案馆(室)、
							数字化加工领域具有丰富的项目建设经验。同时在档案信息<span>电子化管理</span>、图文影像处理和<span>人工智能识别</span>方面
							形成了自己的核心技术，并拥有多项自主研发的软件产品和专利技术。
						</p>
					</div>
					<div class="two" v-show="this.List[1].display">
						<p><span>团队核心成员</span></p>
						<p>有来自BAT等知名国内互联网巨头的资深产品和研发，有来自用友/金蝶等知名企业的档案管理专家，也有来自东软、软通动力等传统企业服务厂商的资深客户成功顾问。</p>
						<p>期待你的加入</p>
					</div>

				</div>
			</div>
		</div>
<!--    <timeline></timeline>-->
	</div>
</template>

<script>
// import Timeline from "@/views/components/timeline.vue";
	export default {
    // components: {Timeline},
		data() {
			return {
				List: [{
						src: require('../assets/images/present.png'),
						title: '公司简介',
						display:true
					},
					{
						src: require('../assets/images/team.png'),
						title: '核心团队',
						display:false
					}
				]
			}
		},props:['timeline'],
		methods: {
			display(index){
				this.List.forEach(obj => obj.display = false);
				this.List[index].display = true;
			}
		}
	}
</script>

<style scoped>
	.section-one .banner {
		height: 494px;
		background-image: url('../assets/images/product_bg.jpg');
	}
	.section-one .banner h2{
		line-height: 392px;
		text-align: center;
		color: #fff;
		font-size:36px;
	}

	.section-one .tab {
		margin-top: -90px;
		margin-bottom: 70px;
		padding: 0 120px 120px;
	}

	.section-one .tab .top ul {
		display: flex;
		height: 150px;
		background: #fff;
		border-radius: 4px;
		box-shadow: 0 18px 54px #f0f2f4;
		border: none;
		margin-bottom: 50px;
	}

	.section-one .tab .top ul li {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 26px;
		position: relative;
	}

	.section-one .tab .top ul li:first-child::after {
		content: '';
		width: 1px;
		height: 100px;
		background-color: #d8e2ff;
		position: absolute;
		right: 0;
	}

	.section-one .tab .top ul li:hover {
		color: #0f9dfc;
	}

	.section-one .tab .top .current {
		color: #0f9dfc;
	}

	.section-one .tab .bottom  p {
		margin-bottom: 20px;
		font-size: 18px;
		color: #808080;
		line-height: 40px;
	}

	.section-one .tab .bottom p span {
		color: #333;
		font-size: 24px;
		text-decoration: underline #0f9dfc;
	}
	.section-one .tab .bottom  .two p:last-child{
		text-align: right;
		color: #333;
		font-size: 22px;
	}
	@media(max-width:1200px){
		.section-one .banner{
		height: 450px;
		}
	}
	@media(max-width:992px){
		
	}
	@media(max-width:768px){
		.section-one .banner{
		height: 400px;
		}

		.section-one .tab{
			margin-bottom: 0px;
    		padding: 0 30px 60px;
		}
		.section-one .tab .bottom p{
			font-size: 14px;
		}
		
		.section-one .tab .bottom p span{
			font-size: 18px;
		}
		.section-one .tab .bottom .two p:last-child{
			font-size: 20px;
		}
		.section-one .tab .top ul{
			height: 100px;
		}
		.section-one .tab .top ul li{
			font-size: 18px;
		}
		.section-one .tab .top ul li img{
			display: none;
		}
		.section-one .banner h2{
		line-height: 400px;
		font-size:30px;
	}
	}
	@media(max-width:562px){
		.section-one .banner{
		height: 320px;
		}
		.section-one .banner h2{
		line-height: 320px;
		font-size:24px;
	}
	}
	@media(max-width:442px){
		.section-one .banner{
		height: 260px;
		}
		.section-one .banner h2{
		line-height: 260px;
		font-size:24px;
	}
	}
</style>
