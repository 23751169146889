

<template>

	<div>
		<!-- 全局注册,导航栏和侧边导航栏 -->
		<navAndSide></navAndSide>
		<router-view></router-view>
		<footNav></footNav>
	</div>
</template>

<script>

import $ from 'jquery'

export default {
  name: 'App',
  components: {},
  data: function () {
    return {}
  },
  created:function(){
    console.log($('#app'));
  }
}



</script>



<style scoped>
</style>
