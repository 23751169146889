<template>
  <div>
    <div class="main-box">
      <div class="banner">
        <h2>        {{formData.title}}</h2>
      </div>
      <h2 class="title">
        {{formData.title}}
      </h2>
      <div class="tow-title">
        {{formData.titleTow}}
      </div>

      <div class="content">
        {{formData.content}}
<!--        禾祺数字档案管理系统是由禾祺技术团队自主设计研发，采用科学的管理理念，满足各档案馆(室)、机关单位、企业等档案管理的信息一体化的数字化综合管理系统-->
<!--        本系统实现对各类档案的科学化分类管理,引入全文检索、数据可视化、ORC、动态水印、流程协同等技术,为客户提供安全可靠、操作简单、提高档案管理效率的数字化档案管理解决方案。-->
        <div class="img" :class="{imgbig:formData.id==='jituan',imgmodel:formData.id==='gaoxiao'}" :style="{ backgroundImage: `url(${formData.img})` }" >


        </div>
      </div>

<!--      <el-row>-->
<!--        <el-col :span="4" :offset="10">数字档案管理系统解决方案</el-col>-->


<!--      </el-row>-->
<!--      <el-row>-->
<!--        -->
<!--        <el-col :span="8" :offset="8">禾祺数字档案管理系统是由禾祺技术团队自主设计研发,采用科学的管理理念,满足各档案馆(室)、机关单位、企业等档案管理的信息一体化的数字化综合管理系统-->
<!--          本系统实现对各类档案的科学化分类管理,引入全文检索、数据可视化、ORC、动态水印、流程协同等技术,为客户提供安全可靠、操作简单、提高档案管理效率的数字化档案管理解决方案。</el-col>-->
<!--      </el-row>-->
<!--      <el-row>-->

<!--        <el-col :span="8" :offset="8">-->
<!--          <img src="../../../assets/images/solution/img.png">-->
<!--        </el-col>-->
<!--      </el-row>-->


    </div>
  </div>

</template>

<script>
export default {
  name: "digitalArchive",
  created() {
  this.getData()

    },
  methods:{
    getData(){
      let id=this.$route.query.id
      this.dataList.forEach(item=>{
        if(item.id==id){
          this.formData=item
        }
      })
    }
  },
  mounted() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0)
    })
  },
  data(){
    return {

      formData : {

      },
      dataList :[
        {id:"dangan",title:"数字档案管理系统解决方案",img:require("@/assets/images/solution/img.png"),titleTow:" 方案概述",content:"禾祺数字档案管理系统是由禾祺技术团队自主设计研发,采用科学的管理理念,满足各档案馆(室)、机关单位、企业等档案管理的信息一体化的数字化综合管理系统\n" +
              "本系统实现对各类档案的科学化分类管理,引入全文检索、数据可视化、ORC、动态水印、流程协同等技术,为客户提供安全可靠、操作简单、提高档案管理效率的数字化档案管理解决方案。"},
        {id:"danganguan",title:"数字档案馆解决方案",img:require("@/assets/images/solution/danganguan.png"),titleTow:"方案概述",content:"一、实现档案收集自动化、档案管理科学化、档案利用网络化、档案服务人性化、档案存储安全化，推动档案馆发展的创新性、有序性和持续性。\n" +
              "二、实现馆室数据规范管理、电子文件的接收、馆室互动、三级联动、馆室及室室之间的资源共享、便利民生服务。\n" +
              "三、实现区域内各县（市、区）档案数据互联互通，推进“一网通办”建设，满足群众“最多跑一次”要求。借助互联网的优势，深入了解广大群众的多样化需求，促进档案服务的不断创新和发展。着力创建“互联网+档案”服务新模式。"},
        {id:"gaoxiao",title:"高校数字档案系统解决方案",img:require("@/assets/images/solution/gaoxiao.png"),titleTow:"方案概述",content:"依据教育事业国家相关规范标准。帮助高校用户更好地利用信息技术获取、处理、传输、应用档案资源，提高档案管理效率与效益，逐步实现高校档案资源数字化、信息服务网络化、流程处理自动化和档案信息知识化，最终将高校档案馆建成以档案馆为主题，档案馆、校史馆、博物馆连成一体的综合性档案馆，以促进文化与科技融合，发挥档案在科学研究、知识教育、资源共享、决策支持等方面的作用，为“智慧校园”、“互联网+校园”建设提供助力。"},
        {id:"jituan",title:"集团企业数字档案管理解决方案",img:require("@/assets/images/solution/jituan.png"),titleTow:"方案概述",content:"       遵循《数字档案室建设指南》、《企业数字档案馆（室）建设指南》等标准规范要求，基于开放档案信息系统（OAIS）模型，采用J2EE平台开发，通过对档案信息资源的“收集、管理、存储、利用”进行全过程信息化管理，实现档案管档案资源数字化、档案管理信息化、档案服务知识化。"},

      ]

    }},
  watch: {
    // 监视id的变化
      $route : {
      deep: true,
      handler() {
       this.getData()
      },
    },
  },



  }

</script>

<style scoped>
    .main-box{
      width: 100%;
      height: auto;

    }
    .title{

      width: 60%;
      height: 50px;
      margin: 0 auto;
      text-align: center;
      line-height: 50px;
      font-size: 32px;
    }
    .tow-title{
      width: 60%;
      height: 40px;
      margin: 0 auto;
      margin-top: 20px;
    //text-align: center;
      line-height: 40px;
      font-size: 24px;
    }
    .content{

      width: 60%;
      min-height: 900px;
      margin: 0 auto;
      margin-top: 20px;
      //text-align: center;
      line-height: 40px;
      font-size: 18px;
    }


    .content>.img{

      text-align: center;
      width: 800px;
      height: 800px;
      margin: 0 auto;
      margin-top: 40px;
      background-repeat: no-repeat;
    //background-size: cover;     background-size: contain;
      background-clip: content-box;
      background-size: contain;
    }
    .imgbig{
      width: 1200px !important;
      height: 1200px !important;
    }
    .imgmodel{
      width: 1000px !important;
      height: 1000px !important;
    }




    @media screen and (max-width: 1680px) {
      .content>.img{

        text-align: center;
        width: 600px;
        height: 600px;
        margin: 0 auto;
        margin-top: 40px;
        background-repeat: no-repeat;
      //background-size: cover;     background-size: contain;
        background-clip: content-box;
        background-size: contain;
      }
      .imgbig{
        width: 800px !important;
        height: 800px !important;
      }
      .imgmodel{
        width: 700px !important;
        height: 700px !important;
      }


    }

    @media screen and (max-width: 1440px) {
      .content>.img{

        text-align: center;
        width: 500px;
        height: 500px;
        margin: 0 auto;
        margin-top: 40px;
        background-repeat: no-repeat;
      //background-size: cover;     background-size: contain;
        background-clip: content-box;
        background-size: contain;
      }
      .imgbig{
        width: 700px !important;
        height: 700px !important;
      }
      .imgmodel{
        width: 600px !important;
        height: 600px !important;
      }


    }






    .banner {
      height: 494px;
      background-image: url('../../../assets/images/nav/li.webp');
      background-repeat: no-repeat;

    }
      .banner h2{
      line-height: 392px;
      text-align: center;
      color: #fff;
      font-size:36px;
    }

</style>