<template>
  <div>
    <div class="container">
       <el-row>

         <el-col :span="12" :offset="3">
           <el-card id="cardBox">
             <h2 class="tit">{{newsData.noticeTitle}}</h2>
             <div class="detailsInfoMod">

               <div class="time">


                 <span>{{newsData.createTime}}</span>

               </div>

               <div class="iconmod">


               </div>


             </div>
             <div class="detailsCon" id="detailsCon" v-html="newsData.noticeContent">

<!--               <p><span style="color:#e74c3c;"><span style="font-size:8px;"><span style="font-family:Microsoft YaHei;">作者：{{newsData.createBy}} </span></span></span></p>-->
<!--                    {{newsData.noticeContent}}-->
<!--               <p>绿水青山就是金山银山。在“双碳”背景下，可再生能源正在成为未来能源提供的主力军，但是以风电和光伏发电为代表的可再生能源具有波动性、随机性，亟需灵活调节电源和储能调节的措施。</p>-->

<!--               <p>推进抽水蓄能快速发展，适应新型电力系统建设和大规模高比例新能源发展需要，助力实现碳达峰、碳中和目标，国家能源局发布《抽水蓄能中长期发展规划（2021-2035年）》，抽水蓄能产业正迎来前所未有的发展机遇。为百年工程装上“智慧大脑”，数字化的建设手段让抽蓄电站在短时间内实现规模复制成为可能。</p>-->

<!--               <p><strong><span style="color:#2980b9;">大势所趋，抽水蓄能将迎来黄金十年</span></strong></p>-->

<!--               <p>如今大力发展可再生能源已成为能源转型和应对气候变化的全球共识。可再生能源成为了全球能源低碳转型的主导方向。在可再生能源中，以风电、光伏发电为代表的新能源呈现性能快速提高、经济性持续提升、应用规模加速扩大的态势。-->
<!--                 但是风电和光伏发电出力具有波动性、随机性，其大规模直接接入会对电力系统安全稳定运行造成影响，新能源的大规模开发和高比例消纳需要电力系统灵活调节电源和储能调节措施。-->
<!--                 用水来做大自然的绿色“充电宝”被证明十分有效。抽水蓄能电站能够实现将蓄能和水力发电结合为一体，成为了经济可靠的绿色“充电宝”。以水为介质，抽水蓄能电站在电力系统电量富余时，将水抽到高处储存；在电力不足时，放水发电，实现“储能—发电—备用”。相比火电站，抽水蓄能电站可以在最短的时间内对电网波动作出反应，按需发电或抽水蓄能；相比风电和光伏发电，抽水蓄能受自然条件的影响较小。抽水蓄能具有调峰、填谷、储能、调频、调相和备用等多种功能，在保障全球电力系统安全运行和促进新能源大规模发展方面重要性日益突出，成为全球可再生能源领域新的增长点。-->
<!--                 在大力推动风电和光伏发电发展的主旋律下，加快抽水蓄能发展是大势所趋。中国发布了《抽水蓄能中长期发展规划（2021-2035年）》，制定了雄心勃勃的发展目标，印度等国家也加大了对抽水蓄能电站的研究和建设支持力度。据统计，截至2020年年底，全球抽水蓄能电站总装机规模为15949万kW，其中，中国抽水蓄能装机容量为3149万kW，约占全球抽水蓄能装机容量的19.7％，装机规模居世界首位。-->
<!--                 对于我国而言，“十四五”期间是建设抽水蓄能的关键期，对构建新型电力系统，促进可再生能源大规模高比例发展，实现碳达峰碳中和目标，保障电力系统安全稳定运行具有关键意义。《抽水蓄能中长期发展规划（2021-2035年）》明确，目标到2030年，我国抽水蓄能电站投产总规模达到1.2亿千瓦。未来15年时间中国抽水蓄能装机量将迎来约10倍的增长。</p>-->

<!--               <p><span style="color:#2980b9;"><strong>以技代管，行业智慧化平台呼之欲出</strong></span></p>-->

<!--               <p>大规模建设期即将到来。在明确的政策和市场驱动下，一大批建设条件优越的抽水蓄能项目将开工建设。“十四五”期间抽水蓄能电站的建设数量将超过200个，已建和在建规模将跃升至亿干瓦级。开发建设和服务范围将实现对大陆区域的全覆盖。考虑到在建项目的合理工期，预计到2025年我国抽水蓄能电站装机容量达到6200万千瓦。-->
<!--                 面对巨大的发展前景，越来越多的企业开始布局抽蓄行业。除国网新源、南网双调两家外，三峡集团、国家能源投资集团有限责任公司、国家电力投资集团有限公司、江苏国信、华源电力有限公司等新的投资主体将投资建设抽水蓄能电站。中国建筑集团有限公司、中国铁建股份有限公司、中国中铁股份有限公司等施工单位将加入中国电建、中国能建、中国安能等传统电力建设的队伍，央企、国企、民企等同参与、共建设的多元化局面基本形成。-->
<!--                 需求的急剧膨胀也对供给能力带来挑战。要知道，建设一个抽蓄电站需要六七年的建设工期以及几十亿的投入，数千工人和设备来来回回，共同建设这些百年工程。不同的自然环境对抽蓄电站的建设要求各不相同，这些都需要新的技术手段来提高建设水平。抽水蓄电站“少人化、机械化、智能化、标准化”的发展趋势将愈加明显。在众多数字手段中，数字孪生与智能建造水平将持续提高并广泛应用，设计施工一体化协同技术和管理系统将不断改进，并为更多建设和运维场景提供数字化映射、智慧化模拟、精准化决策。</p>-->

<!--               <p><span style="color:#2980b9;"><strong>智慧大脑，服务抽蓄电站百年工程建设</strong></span></p>-->

<!--               <p>从无到有，从有到优，抽蓄电站的智慧化管理水平逐步迈上新台阶。国网新源集团有限公司作为国家电网公司的全资子公司和直属单位，是以抽水蓄能储能电源业务为核心的专业化公司，负责开发建设和经营管理国家电网公司经营区域内的抽水蓄能电站。截至2023年2月底，国网新源公司累计投产21个项目、装机容量2432.32万千瓦，占全国投产容量73%，在建抽水蓄能电站39座，累计在建容量5573万千瓦，占全国在建容量77%。国网新源公司坚持以工程建设为中心，以设计、施工、设备管理为主线，强化安全、质量、进度、造价、技术和综合“5+1”专业化管控，持续优化完善具有公司特色的基建管理体系，打造“智慧建造”新标杆，引入“智慧工地”应用，作为其施工管控和数字管理平台，为施工过程管控赋能。</p>-->

<!--               <p>作为该平台的建设方，易用视点总经理叶红星在接受通信世界全媒体记者采访时讲道，国网新源基建数字化管控平台是采用三维可视化、数据可视化、物联网数据实时采集等技术构建的一套覆盖数字化大坝、数字化洞室、数字化机组的施工全过程管理平台。该平台通过数字孪生技术，构建与大坝实体一致的数字孪生体，通过自动构建的单元工程模型+施工标准化数据+物联数据集成，进行抽蓄电站工程规划、设计、施工、验收、生产运行各阶段全过程管理，实现基建智慧管控。-->
<!--                 作为中国能源电力行业数字化建设领航者，易用视点孙健介绍道，易用视点通过多年实践，建设了三大平台和四大应用，非别是基建数字平台(EVB)、智能物联平台(EVT)、数字孪生平台(EVG)平台，智慧工地、基建数字化、数字孪生、数字档案应用。从工程建设到运营管理，全方位支撑抽蓄电站智慧化建设、智能化运维，并实现了实践经验的快速规模复制。-->
<!--                 如今易用视点已经和国网新源、中国电建、中国能建、中国华电、国网南瑞、国网信息通信产业集团、国电集团、华源电力、南方电网、中国华能、雅砻江水电、三峡等集团级客户以及上百家水力发电客户进行了深入且广泛的合作，在丰满水电站等重要项目的建设中做出了较大的贡献，得到了业主方的充分肯定。</p>-->

<!--               <p>“未来，智能基建平台要做抽蓄行业基建领域的‘数字大脑’，采用物联网、数字孪生、大数据、人工平台等先进技术手段，减少人员作业风险，提高工程建设质量、管理水平以及建设效率，更好地服务‘十四五’时期抽蓄电站蓬勃发展的新需求。”叶红星总结道。</p>-->

<!--               <p>在“双碳”背景下，抽水蓄能即将迎来下一个黄金十年。数字化建设平台将推动更多智慧化的“超级充电宝”工作在祖国的大江南北，让天更蓝，水更清，推动我国“双碳”战略全面落实，实现数字经济绿色可持续发展。</p>-->

             </div>


             <div class="detailsGuideMod">


               <div class="line"></div>

               <div class="info">
<!--                 <a class="btn" href="javascript:;">-->

                 <a  href="javascript:;">
<!--                   上一篇-->

                 </a>


                 <a :href="backUrl">

                   <div class="returns">

                     <div class="icon">
                        <img src="../../assets/images/back.png">


                     </div>

                     <span>返回列表</span>

                   </div>
                 </a>


                 <a href="javascript:;">

<!--                   下一篇-->



                 </a>


               </div>

             </div>

           </el-card>
         </el-col>
         <el-col :span="8" :offset="1" style=" box-sizing: border-box;">
           <el-card class="hotNewsBoxes animate__fadeIn animate__fadeOut"  v-show="showHot"  >
             <div class="hotnews">

               <h4 class="tit">热门新闻</h4>
               <div class="img">
                 <img :src="hotImgSrc" alt="禾其科技用心打造未来">
               </div>

               <div class="hnlist" v-show="showHot">


                 <a v-for="(item,index) in newsHot"  :href="'#/infolistItem?id='+item.noticeId" :key="index" class="items" @click="getNew(item.noticeId)" >

                                {{item.noticeTitle}}
                 </a>
<!--                 <a href="/enterprisenews/info.html?id=45" class="items">国家电网大力投资抽水蓄能 2023年计划装机规模增35%</a>-->



<!--                 <a href="/enterprisenews/info.html?id=44" class="items">捷报贺新春！虎年抽蓄核准成绩单！！</a>-->



<!--                 <a href="/enterprisenews/info.html?id=43" class="items">重磅！2023年版《bp世界能源展望》正式发布</a>-->



<!--                 <a href="/enterprisenews/info.html?id=47" class="items">为百年工程装上“智慧大脑”，迎接抽蓄行业的黄金十年</a>-->



<!--                 <a href="/enterprisenews/info.html?id=42" class="items">提速！“十四五”已核准抽水蓄能总装机规模超3000万千瓦</a>-->





               </div>





             </div>

           </el-card>
         </el-col>
       </el-row>



    </div>
  </div>

</template>

<script>
// 动画 animate.css
// import animate from "animate.css";
import {getNoticeById, getNoticeHot} from "@/api/kms/notice";
export default {

  name: "infolistItem",
  data(){
    return {
      showHot:true,
      newsData:{},
      newsHot:[],
      hotImgSrc:'',
      backUrl:'#/news',

    }
  },
  mounted() {
    this.hotSpotScroll()




  },
  created() {
    this.getNew()
    this.getHot()
  },
  methods:{
    hotSpotScroll(){

      window.addEventListener("scroll", this.scrollPage)

    },
    scrollPage(){
      if(this.$route.query.back){
        this.showHot=false
        return
      }
      let cardBoxHeight = document.getElementById("cardBox");

      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let height = cardBoxHeight.offsetHeight;
      // console.log("scrollTop",scrollTop)
      // console.log("height",height)

      if ( height - scrollTop < 800 ) {
        this.showHot = false
      }else {
        this.showHot = true
      }
    },
    //获取新闻内容
    getNew(id){
      if(this.$route.query.back){
        this.backUrl=this.$route.query.back
        this.showHot=false
      }

      getNoticeById(id?id:this.$route.query.id).then(response=>{
        this.newsData= response.data.data
      })
    },


    //获取前五个新闻热点
    getHot(){
      getNoticeHot(this.$route.query.id).then(response=>{
        this.newsHot= response.data.rows
        if(this.newsHot){
          this.hotImgSrc=process.env.VUE_APP_BASE_API+ this.newsHot[0].coverPath
        }
      })
    },



  },destroyed() {
    window.removeEventListener("scroll",this.scrollPage)
  }
}
</script>

<style scoped>

@media (min-width: 768px) {


  #detailsCon>>>iframe{
    width: 100%;
    height: 320px;
  }
}

.container {
  width: 1440px;
}

@media (min-width: 993px) {


  #detailsCon>>>iframe{
    width: 100%;
    height: 360px;
  }
}
@media (min-width: 1200px){
.container {
  width: 1440px;
}
  #detailsCon>>>iframe{
    width: 100%;
    height: 400px;
  }
  #detailsCon>>>p{
    line-height: 35px;
  }
}
@media (min-width: 1799px) {
  .container {
    width: 1440px;
  }
  #detailsCon>>>iframe{
    width: 100%;
    height: 400px;
  }
  #detailsCon>>>p{
    line-height: 35px;
  }

}







.container {

  padding-right: 15px;
  padding-left: 15px;
  margin-top: 5%;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 15%;
}
.detailsInfoMod {
  padding-bottom: 38px;
  margin-bottom: 38px;
  border-bottom: 3px solid #e6e6e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.detailsInfoMod .time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.detailsInfoMod .iconmod {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.detailsInfoMod .time span {
  color: #ccc;
  font-size: 20px;
  line-height: 1;
}
  .tit {
  font-weight: normal;
  font-size: 30px;
  color: #333333;
  margin: 15px 0 30px;
  line-height: 1.4;
}
.detailsCon{
  min-height: 800px;
}
.detailsCon p {
  font-size: 18px;
  line-height: 2;
  margin-bottom: 20px;
}
.detailsGuideMod {
  margin-top: 100px;
}
.detailsGuideMod .line {
  margin-bottom: 40px;
  width: 100%;
  height: 1px;
  background: #e6e6e6;
}
.detailsGuideMod .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.detailsGuideMod .info .btn {
  width: 198px;
  height: 60px;
  font-size: 18px;
  color: grey;
  border: 1px solid #e6e6e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.detailsGuideMod .info .returns .icon {
  margin-bottom: 6px;
  width: 58px;
  height: 58px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #e7e7e7;
  overflow: hidden;
}
.detailsGuideMod .info .returns img {
  width: 30px;
  height: 30px;
  fill: grey;
}
.hotNewsBoxes{
  width: 33%;
  position: fixed;
  top: 10%;
}
.hotnews {
  padding: 60px 60px 30px;
  background: #fff;
}
.hotNewsBoxes .hotnews .tit {
  position: relative;
  font-weight: 400;
  font-size: 36px;
  line-height: 1.4;
  margin-bottom: 35px;
}
.hotnews .img {
  margin-bottom: 20px;
}
.hotNewsBoxes .hotnews .hnlist .items {
  display: block;
  padding-left: 18px;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
}

.hotNewsBoxes .hotnews .hnlist .items::after {
  position: absolute;
  top: 10px;
  left: 0;
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #073d96;
  overflow: hidden;
}
.hotNewsBoxes .hotnews .hnlist .items:hover{
  color: #073d96;


}
.img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
}

.fade-enter-active, .fade-leave-active {
  /* opacity 透明度 */
  transition: opacity 1s
}
/* .fade-leave-active, 2.1.8 版本以下 */
.fade-enter, .fade-leave-to  {
  opacity: 0
}



</style>