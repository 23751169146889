<template>
  <div>
    <div class="main-box">
      <div class="content-box">
        <div class="content-head">
          <div style="text-align: center;"><span style="font-size: 38px;"><font style="color: #0f9dfc ;    text-align: center; font-weight: 400;" >解决方案</font></span></div>
          <div style="text-align: center;"><span style="font-size: 14px;"><font style="color: rgb(153, 162, 169);" >SOLUTION</font></span></div>
          <div style="text-align: center;"><font color="#99a2a9"><span style="font-size: 14px;"><b><font style="color: rgb(15, 157, 252);" color="#0f9dfc">——</font></b></span></font></div>
        </div>
        <div class="content">

          <div class="content-div" @click="openDigitalArchive('dangan')">
            <div class="content-div-img">
              <img src="../../assets/images/solution/index1.webp">
            </div>
            <div class="content-div-title">
              数字档案管理
            </div>
          </div>
          <div class="content-div"  @click="openDigitalArchive('jituan')">
            <div class="content-div-title">
              集团企业档案管理
            </div>
            <div class="content-div-img">
              <img src="../../assets/images/solution/index2.webp">
            </div>

          </div>
          <div class="content-div"  @click="openDigitalArchive('gaoxiao')">
            <div class="content-div-img">
              <img src="../../assets/images/solution/index3.webp">
            </div>
            <div class="content-div-title">
              高校档案管理
            </div>
          </div>
          <div class="content-div"  @click="openDigitalArchive('danganguan')">
            <div class="content-div-title">
              档案馆三网建设
            </div>
            <div class="content-div-img">
              <img src="../../assets/images/solution/index4.webp">
            </div>

          </div>


        </div>


      </div>


    </div>

  </div>

</template>

<script>
export default {
  name: "solution",
  methods:{
    openDigitalArchive(id){
      this.$router.push({path:'/digitalArchive',query: {id:id}})
    }

  }
}
</script>

<style scoped>
.main-box{
  width: 100%;
  height: 500px;
  text-align: center;
  margin-top: 40px;
  padding-top: 40px;

}
.content{

    width: 65%;
    height: 395px ;
  text-align: center;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 1920px) {
  .content{

    width: 65%;
    height: 395px ;
    text-align: center;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
  }
}
@media screen and (max-width: 1680px) {
  .content{
  width: 70%;
  height: 355px ;
  text-align: center;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
  }
}

@media screen and (max-width: 1450px) {
  .content {
    width: 75%;
    height: 335px;
    text-align: center;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
  }
}

  .content-div{
  width: 24%;
  height: 100%;

}

.content-div>.content-div-img{
  width: 100%;
  height: 80%;
  display:flex;
  align-items:center;
  justify-content:center;
  //overflow: hidden;
}

.content-div>.content-div-img>img{
  width: 100%;
  height: 100%;


}
.content-div>.content-div-title{
  width: 100%;
  height: 20%;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: #0f9dfc;
  color: #FFFFFF;
  font-size: 16px;
}

</style>