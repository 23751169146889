<template>
	<div>
		<div class="section-one">
			<div style="height:68px"></div><!-- 占位盒子 -->
			<div class="content" v-for="(obj,index) in tabList" :key="index" v-show="obj.link === ('#'+ $route.path)">
				<h1>{{obj.name}}</h1>
				<h6>{{obj.description }}</h6>
			</div>
		</div>
		<div class="section-two">
			<div class="tab-box">
				<ul>
					<li data-aos="fade-up" v-for="(obj, index) in tabList" :key="index" :class="{ current: obj.link === ('#'+ $route.path) }"><a :href="obj.link"
							@click="tabShow(index)">{{ obj.name }}</a></li>
				</ul>
			</div>
		</div>
		<router-view></router-view>
<!--		<div class="section-night">
			<h3>开始体验我们的服务</h3>
			<p>现在开始尝试更简单高效的工作方式</p>
			<button onclick="window.open('http://www.gzyrkj.net/arcamel-admin/toLogin')">开始体验</button>
		</div>-->
	</div>
</template>

<script>
export default {
	data() {
		return {
			tabList: [{
				name: '档案管理平台',
				description:'从档案采集、管理、保管、利用到鉴定销毁 ，实现档案的全生命周期闭环管理',
				link:'#/product/manage'
			},
			{
				name: '档案四性检测',
				description:"检测电子档案的真实性、可用性、安全性、完整性",
				link:'#/product/use'

			},
			{
				name: '声像档案管理',
				description:"声像档案的形成、整理、归档、移交、保管和借阅工作的要求与方法",
				link:'#/product/identification'
			},
			{
				name: '档案智能著录',
				description: "对档案的内容和形式特征进行分析、选择和记录",
				link:'#/product/intelligence'
			}
			],




		}
	},
	methods: {
		tabShow(index) {
			this.tabList.forEach(obj => obj.display = false)
			this.tabList[index].display = true
		}
	},
}
</script>

<style scoped>
.section-one {
	height: 494px;
	background-image: url('../assets/images/nav/pexels-photo-265087.jpeg');
	background-size: cover;
	position: relative;
}

.section-one .content {
	margin: 100px auto 60px;
	color: #fff;
	text-align: center;
	transition: all 2s;
}

.section-one .content h5 {
	font-size: 30px;
	margin-bottom: 25px;
	font-weight: 400;
}

.section-one .content h1 {
	font-size: 42px;
	margin-bottom: 25px;
	font-weight: 500;
}

.section-one .content h6 {
	font-size: 20px;
	margin-bottom: 30px;
	font-weight: 400;
}

.section-one .content button {
	box-shadow: none;
	font-size: 16px;
	width: 140px;
	height: 40px;
	line-height: 40px;
	padding: 0;
	margin: 0 10px;
	background: #0f9dfc;
	color: #fff;
	border-radius: 50px;
	border: none;
}

.section-two .tab-box {
	height: 60px;
	line-height: 60px;
	background-color: #f5f7fa;
	color: #333;
	font-size: 18px;
	margin-bottom: 40px;
}

.section-two .tab-box ul {
	display: flex;
	justify-content: space-evenly;
}


.section-two .tab-box .current {
	color: #0f9dfc;
	border-bottom: 3px solid #0f9dfc;
}

.section-night {
	position: relative;
	text-align: center;
	background-color: #f7fafe;
	padding: 20px 0 50px;
}

.section-night h3 {
	color: #333;
	font-size: 30px;
	font-weight: 400;
}

.section-night p {
	margin: 20px 0 40px;
	color: #555;
	font-size: 18px;
}

.section-night button {
	width: 140px;
	border: none;
	background: #0f9dfc;
	color: #fff;
	border-radius: 50px;
	padding: 10px 20px;
	font-size: 16px;
	box-shadow: 4px 4px 6px rgba(88, 187, 252, .6);
}
@media(max-width:1200px){

}
@media(max-width:992px){

}
@media(max-width:768px){
	.section-one{
		height: 392px;
	}
	.section-one .content{
		margin: 30px auto 20px;
	}
	.section-one .content h1{
		font-size: 36px;
	}
	.section-one .content h6{
		font-size: 16px;
		padding: 0 15px;
	}
	.section-one .content button{
		font-size: 14px;
		width:120px;
	}
	.section-two .tab-box{
		font-size: 16px;
		margin-bottom: 0px;
	}
	.section-night h3{
		margin-top: 0;
		font-size: 18px;
	}
	.section-night p{
		font-size: 14px;
		margin: 10px auto 15px;
	}
}
@media(max-width:442px){
	.section-one{
		height: 242px;
	}
	.section-one .content{
		margin: 0px auto;
	}
	.section-one .content h1{
		font-size: 24px;
		margin-bottom: 15px;
	}
	.section-one .content h6{
		font-size: 14px;
		padding: 0 15px;
		margin-bottom: 15px;
	}
	.section-one .content button{
		font-size: 14px;
		width:80px;
		height: 30px;
		line-height: 30px;
	}
	.section-two .tab-box{
		font-size: 12px;
	}
}
</style>