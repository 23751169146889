<template>
  <div>
    <div class="main-box">
      <div class="content-box">
        <div class="content-head">
          <div style="text-align: center;"><span style="font-size: 38px;"><font style="color: #0f9dfc ;    text-align: center; font-weight: 400;" >产品中心</font></span></div>
          <div style="text-align: center;"><span style="font-size: 14px;"><font style="color: rgb(153, 162, 169);" color="#99a2a9">PRODUCTS</font></span></div>
          <div style="text-align: center;"><font color="#99a2a9"><span style="font-size: 14px;"><b><font style="color: rgb(15, 157, 252);" color="#06b2b6">——</font></b></span></font></div>
        </div>
        <div class="content">
            <div class="content-row">
              <div class="content-row-text">
<!--              <h3 >ERP系统</h3>-->
                <div class="title">档案管理平台</div>

              <p class="colorP">  档案门户、个人执行、档案收集、档案管理、档案检索、利用管理、档案保管、档案统计、档案编研、档案设置、
                                  权限管理、日志管理、档案工具、工作流管理
              </p>
              </div>
              <div class="content-row-text">

                <div class="title">多媒体档案管理系统</div>
                <p class="colorP">     音视频文件接收、转换、元数据捕获；音视频档案管理、检索、统计。人脸信息标注、人脸检索、多人照片智能检索、以图搜图。</p>
              </div>

              <div class="content-row-text">

                <div class="title">四性检测系统</div>
                <p class="colorP">  对电子文件进行真实、安全、完整、可用检测、四性检测管理、四性检测报告。</p>
              </div>


            </div>

          <div class="content-row">
            <div class="content-row-text">
              <!--              <h3 >ERP系统</h3>-->
              <div class="title">智能开放鉴定系统</div>
              <p class="colorP">        NLP智能鉴定、鉴定初审、鉴定协同会审、鉴定复审、鉴定规则管理、AI鉴定训练、鉴定统计大屏展示。</p>
            </div>
            <div class="content-row-text">

              <div class="title">知识管理系统</div>
              <p class="colorP">   知识新建、知识分享、知识全文检索、知识图谱、知识文档库、知识文档调用接口。     </p>
            </div>

            <div class="content-row-text">

              <div class="title">智能著录系统</div>
              <p class="colorP"> 看图著录、OCR著录、扫描分件、拆件、合件 。 </p>
            </div>


          </div>
        </div>
        <div class="foot" data-aos="fade-up">
          <div class="foot-box">
            <div class="foot-box-item">
              <div class="foot-title-one "><span class="colorWhite">100</span></div>
              <div class="foot-title-tow "><span class="colorWhite">降低风险率</span></div>
              <div class="foot-content "><span class="colorWhite">前期对产品功能定义及目标用户群调研，上线后进行用户可用性测试</span></div>

            </div>
            <div class="foot-box-item">
              <div class="foot-title-one "><span class="colorWhite">70</span></div>
              <div class="foot-title-tow "><span class="colorWhite">战略伙伴</span></div>
              <div class="foot-content "><span class="colorWhite">合作的客户中，70%以上的客户都签订了长期战略合作协议。</span></div>

            </div>
            <div class="foot-box-item">
              <div class="foot-title-one "><span class="colorWhite">300+</span></div>
              <div class="foot-title-tow "><span class="colorWhite">经典案例</span></div>
              <div class="foot-content "><span class="colorWhite">凭借对服务品质和商业价值的追求，赢得了众多国内外客户的信任。</span></div>

            </div>
          </div>


        </div>


      </div>


    </div>

  </div>

</template>

<script>
export default {
  name: "products"
}
</script>

<style scoped>
.main-box{
  width: 100%;
  height: 945px;
  padding-top: 40px;
  margin-bottom: 80px;

  background-color: #f8f9fa;
  //text-align: center;
}
.content{
  width: 65%;
  height: 480px ;
  //text-align: center;
  margin: 20px auto;
  //display: flex;
  //justify-content: space-around;
}
.content>.content-row{
  width: 100%;
  height: 40%;
  display:flex;

justify-content: space-around;

}

.content>.content-row>.content-row-text{
  width: 18%;
  height: 100%;

}
.content>.content-row>.content-row-text>.title{
  color: #3d4246;
  font-size: 18px;
}
.content>.content-row>.content-row-text>p{
  margin-top: 10%;
  font-size: 12px;
  line-height: 20px;

}
.main-box>.content-box>.foot{
  width: 100%;
  height: 400px;
  background-image: url("@/assets/images/product.png"), linear-gradient(blue,blue );



  background-blend-mode: lighten;
  background-repeat: no-repeat;
  display:flex;
  background-size: cover;
  justify-content:center;
  align-items:center;

}
.main-box>.content-box>.foot>.foot-box{
  width: 50%;
  height: 50%;
  display: flex;
  justify-content: space-around;
}
.main-box>.content-box>.foot>.foot-box>.foot-box-item{
  text-align: center;
  height: 100%;
  width:28% ;
}

.main-box>.content-box>.foot>.foot-box>.foot-box-item>.foot-title-one>span{
  font-size: 36px;
}
.main-box>.content-box>.foot>.foot-box>.foot-box-item>.foot-title-tow>span{
  font-size: 20px;
}
.main-box>.content-box>.foot>.foot-box>.foot-box-item>.foot-content>span{
  font-size: 12px;
  line-height: 20px;
}
.colorWhite{
  color: #ffffff;

}
.colorP{
  color: #99a2a9;
}

</style>