import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
//引入全局过滤器
import filter from '@/filter'

//引入顶部和底部导航
import navAndSide from './components/navAndSide.vue'
import footNav from './components/footNav.vue'


//引入elementUI
import '@/plugins/element-ui.js'


import AOS from "aos";
import "../node_modules/aos/dist/aos.css";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import progressive from 'progressive-image/dist/vue'; // 渐进式

import 'progressive-image/dist/index.css';//样式

Vue.use(progressive, {
	removePreview: true,
	scale: true
})
// import VueLazyload from 'vue-lazyload'
//
// Vue.use(VueLazyload)
Vue.use(VueAwesomeSwiper)

Element.Dialog.props.lockScroll.default = false;

Vue.use(Element);
// Element.Dialog.props.lockScroll.default = false;
Vue.use(AOS);

// Vue.use(Element)
Vue.config.productionTip = false
//导航栏和底部组件全局注册
Vue.component("navAndSide", navAndSide)
Vue.component("footNav", footNav)

AOS.init({
	duration: 1000,
	easing:"ease-in-out-back"
})


//6.路由对象注入到vue实例中，this可以访问$router和$router
//7.设置挂载点-当url的hash值路径切换，显示规则里对应的组件到这
new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
