import { render, staticRenderFns } from "./customer.vue?vue&type=template&id=5d21ad43&scoped=true&"
import script from "./customer.vue?vue&type=script&lang=js&"
export * from "./customer.vue?vue&type=script&lang=js&"
import style0 from "./customer.vue?vue&type=style&index=0&id=5d21ad43&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d21ad43",
  null
  
)

export default component.exports