<template>
  <div>
    <div class="main-box">
      <div class="content-box">
        <div class="content-head">
          <div style="text-align: center;"><span style="font-size: 38px;"><font style="color: #0f9dfc ;    text-align: center; font-weight: 400;" >新闻资讯</font></span></div>
          <div style="text-align: center;"><span style="font-size: 14px;"><font style="color: rgb(153, 162, 169);" color="#99a2a9">NEWS</font></span></div>
          <div style="text-align: center;"><font color="#99a2a9"><span style="font-size: 14px;"><b><font style="color: rgb(15, 157, 252);" color="#06b2b6">——</font></b></span></font></div>
        </div>
        <div class="content">
          <div class="content-left">
              <div class="content-img">
                <img :src="require('../../assets/images/new1.jpg')">
              </div>
            <div class="content-img">
              <img :src="require('../../assets/images/new2.jpg')">
<!--                <img src="../../assets/images/new2.jpg">-->
            </div>



          </div>
          <div class="content-right">
            <div class="news-row" v-for="item in dataList" :key="item.noticeId">
              <a  href="javascript:void(0);"   @click="openNews(item.noticeId)"   class="new-row-left">
                <div class="new-date">
                  <div class="date">{{item.createTime.substring(8,10)}}
                  </div>
                  <p class="new-year">{{item.createTime.substring(0,7).replace('-','/')}}</p>
                </div>



              </a>
              <div class="new-row-right">

                <a href="javascript:void(0);" class="new-row-right-title"  @click="openNews(item.noticeId)"  >{{item.noticeTitle}}</a>
                <p class="new-row-right-content" v-html="item.remark"></p>
              </div>
            </div>





          </div>





        </div>


      </div>


    </div>
  </div>

</template>

<script>
import {getNewsIndex} from '@/api/kms/notice.js'
export default {
  name: "minNews",
  mounted() {
    this.getNews()
  },
  data(){
    return{
      dataList:[]
    }
  },
  methods:{
    getNews(){
      let query={
        pageNum: 1,
        pageSize: 3
      };
      getNewsIndex(query).then((data)=>{
        console.log("data",data)
        this.dataList=data.data.rows
      })

    },
    openNews(id){
      this.$router.push({path:'/infolistItem',query: {id:id,back:'#/index'}})
    },
    subContnt(text){
      if(text.length>30){
        return text.substring(0,30)+'......'
      }
    }
  }

}
</script>

<style scoped>
.main-box{
  width: 100%;
  height: 680px;
  margin-top: 100px;
  padding-top: 60px;

//text-align: center;
}
.content{

  width: 65%;
  height: 458px;
  //text-align: center;
  margin: 20px auto;
  display: flex;
  justify-content: space-around;
}
.content>.content-left{
  width: 30%;
  height: 100%;
}
.content>.content-left>.content-img{
  width: 100%;
  height: 40%;
  display:flex;
  justify-content:center;
  align-items:center;
  color: #FFFFFF;
  margin-bottom: 5%;
}

.content>.content-left>.content-img img{
  width: 100%;
  height: 100%;
}
.content-right{
  width: 70%;
  height: 100%;
}
.content-right>.news-row{
  width: 100%;
  height: 28%;
  padding-left: 8%;
  padding-top: 4%;
  //padding-bottom: 4%;
}
.content-right>.news-row>.new-row-left{
  display: inline-block;
  width: 20%;
}
.content-right>.news-row>.new-row-left>.new-date{
  background: #f2f2f2;
float: left;
  width: 80px;
  height: 80px;
  text-align: center;
}
.content-right>.news-row>.new-row-left>.new-date>.date{
  font-size: 25px;
  margin-top: 10px;
  color: #666666;

}
.content-right>.news-row>.new-row-left>.new-date>.new-year{
  font-size: 14px;
  color: #666666;

}
.content-right>.news-row>.new-row-right{
  float: right;
  width: 80%;
 height: 80px;
}
.content-right>.news-row>.new-row-right>.new-row-right-title{
  font-size: 16px;
  font-family: 微软雅黑;
  color: #4d4d4d;
  //font-weight: normal;
  text-decoration: none;
  font-style: normal;
  //margin-bottom: 20px;
}
.content-right>.news-row>.new-row-right>.new-row-right-title:hover{
  color: #0f9dfc;
}

.content-right>.news-row>.new-row-right>.new-row-right-content{
  font-size: 13px;
  font-family: 微软雅黑;
  color: #9b9b9b;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  line-height: 20px;
}

</style>