<template>
  <div>

    <el-row type="flex" justify="center">
      <!-- <el-col  :lg="3" :md="2" :sm="1" :xs="0.5"></el-col> -->
      <el-col  :lg="13" :md="15" :sm="17" :xs="23">
        <p class="title">新闻动态</p>
        <p v-if="screenWidth < 768">
          <el-image
              style="width: 100%; height: 100px"
              src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
              :fit="'cover'"
          ></el-image>
        </p>

        <el-row
            type="flex"
            justify="space-between"
            align="middle"
            v-for="(obj, index) in newslist"
            :key="index"
            @click.native="openNews(obj.noticeId)"


        >
          <el-col :span="8" v-if="index == 0&&screenWidth > 768">
            <el-image
                style="width: 200px; height: 100px"
                :src="require(obj.coverPath)"
                :fit="'cover'"
            ></el-image>
          </el-col>

          <el-col class="news-container" :span="12">
            <h5>{{obj.noticeTitle }} ({{ obj.createTime | formdate }})</h5>

            <p v-html="obj.noticeContent">

            </p>
          </el-col>
          <el-col :span="4" style="text-align: center; font-size: 12px">
            {{ obj.createTime | formdate }}
          </el-col>
        </el-row>
        <el-row style="border:none;">
          <el-pagination
              background
              prev-text="上一页"
              next-text="下一页"
              v-show="newslist.length>0"
              :total="newslist.length"
              :current-page="query.pageNum"
              :page-size="query.pageSize"
              :pager-count="5"
              @current-change="updateList"
              layout="prev, pager, next"
          >
          </el-pagination>
        </el-row>
      </el-col>
      <!-- <el-col  :lg="1" :md="1" :sm="1" :xs="0"></el-col> -->
      <el-col  :lg="4" :md="4" :sm="4" :xs="0" :offset="1" class="sidemenu">
        <p class="title">新闻类别</p>
        <ul style="min-width: 400px">
          <li
              v-for="(item, index) in dynamiclist"
              :key="index"
              :class="{dynamicContainer:true,current:active[index]}"
              @click="changeType(item.type)"
          >
            <a href="javascript:void(0);" :class="{  }">{{ item.title }}</a>
          </li>
        </ul>
      </el-col>
      <!-- <el-col  :lg="3" :md="2" :sm="1" :xs="0.5"></el-col> -->
    </el-row>
  </div>
</template>

<script>
import {getInformation} from "@/api/kms/notice";

export default {
  data() {
    return {
      query:{
        noticeType:'',
        pageNum: 1,
        pageSize: 5,
      },

      newslist: [
        // {
        //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   company: "",
        //   description:
        //     "",
        //   date: new Date(),
        // },
        // {
        //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   company: "",
        //   description:
        //     "",
        //   date: new Date(),
        // },
        // {
        //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   company: "",
        //   description:
        //     "",
        //   date: new Date(),
        // },
        // {
        //   url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        //   company: "",
        //   description:
        //     "",
        //   date: new Date(),
        // },
      ],
      dynamiclist: [
        {
          title: "全部动态",
          url: "#",
          type:''
        },
        {
          title: "签约新闻",
          url: "#",
          type:'3'
        },
        {
          title: '案例',
          url: "#",
          type:'4'
        },
        {
          title: '行业资讯',
          url: "#",
          type:'5'
        },
      ],
      screenWidth:null,
      //右侧菜单暂时值，后期采用路由高亮
      active:[true,false,false],
    };
  },

  methods:{
    updateList(val){
      this.query.pageNum = val;
      this.getData();
    },
    getWidth(){
      //用于获得页面中某个元素的左,上,右和下分别相对浏览器视窗的位置，及元素自身的宽度和高度
      this.screenWidth = document.body.getBoundingClientRect().width;
    },
    openNews(id){
      this.$router.push({path:'/infolistItem',query: {id:id,back:'#/consultation'}})
    },
    changeType(type){
      this.query.noticeType=type
      this.getData()
    },
    getData(){

      getInformation(this.query).then(response=>{
        response.data.rows.forEach((item)=>{
          item.coverPath=process.env.VUE_APP_BASE_API+ item.coverPath
        })
        this.newslist=response.data.rows



      })
    }
  },

  created(){
    window.addEventListener('resize',this.getWidth)
  }, mounted() {
          this.getData()

  },

  Destroyed() {
    window.removeEventListener('resize',this.getWidth)
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 12px;
  color: #999;
  margin-bottom: 24px;
}
.el-row {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}

.news-container {
  transition: color 0.5s;
  h5 {
    color: #000;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 0 6px;
  }
  p {
    display: -webkit-box;
    color: #999;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 40px;
    word-break: break-all;
    line-height: 20px;
    -webkit-box-orient: vertical; //子元素应该被水平或垂直排列
    -webkit-line-clamp: 2; //3行后显示省略号
  }
}
.dynamicContainer {
  height: 66px;
  margin-bottom: 6px;
  border-bottom: 1px solid #cccccc;
  a {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    padding: 20px 0;
    padding-left: 0px;
    transition: all .3s;
  }
  a:hover{
    background:rgba($color: #999, $alpha: 0.1);
    text-indent: 2em;
  }
}
.current{
  font-weight: bold;
  border-bottom: 1px solid #333;
}

// *{border: 1px solid red;}
</style>