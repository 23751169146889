<template>
  <div>
    <banner
        company-name="禾祺科技"
        title="资质荣誉"
        description="相信梦想并坚持，我们就能到达想要的未来。"
        :background-url="require('@/assets/images/nav/ry2.jpeg')"
    ></banner>
      <aptitude></aptitude>

<!--    :background-url="require('@/assets/images/nav/ry.jpeg')"-->

  </div>
</template>

<script>
import banner from './components/banner.vue';
import aptitude from './components/aptitude.vue';
export default {
  name: "aptitudeShow",
  components:{banner,aptitude},


}
</script>

<style scoped>










</style>