<template>
  <div>
    <banner
      :company-name="'禾祺科技'"
      :title="'档案管理新闻'"
      :description="'由纸质档案向电子化档案转型的必选工具。'"
      :background-url="require('@/assets/images/nav/new.webp')"


    ></banner>
    <infolist></infolist>
  </div>
</template>
  
  <script>
import banner from "./components/banner.vue";
import infolist from "./components/infolist.vue";
export default {
  components: {
    banner,
    infolist
  },
  data() {
    return {};
  },
};
</script>
  
<style lang="scss" scoped>
</style>