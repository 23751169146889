<template>
  <div>
    <banner
    :companyName="'禾祺科技'"
    :title="'携手共进 合作共赢'"
    :description="'全国各省市诚招合伙人，携手共促档案行业新时代'"
    :backgroundUrl="require('@/assets/images/nav/hz.jpeg')"
    ></banner>
    <div class="section-two">

<!--      <el-table data-aos="fade-up"-->
<!--          :data="offerData"-->
<!--          style="width: 100%"-->

<!--      >-->
<!--        <el-table-column-->
<!--            type="index"-->
<!--            width="50"-->
<!--            label="序号">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            prop="name"-->
<!--            label="产品名称">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            prop="system"-->
<!--            label="品牌型号">-->
<!--        </el-table-column>-->
<!--        <el-table-column-->
<!--            prop="price"-->
<!--            label="价格">-->
<!--        </el-table-column>-->
<!--      </el-table>-->

    </div>
    <div class="section-three">

      <h2  data-aos="fade-up">我们期待这样的您加入</h2>
      <p data-aos="fade-up">与我们一起共赢，建立更好的平台</p>
      <ul>
        <li v-for="(obj, index) in hopeList" :key="index" data-aos="fade-up">
          <div class="left">
            {{ index + 1 }}
          </div>
          <div class="right">
            <h6>{{ obj.identity }}</h6>
            <p>{{ obj.content }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="section-four">
<!--      <h4>
        填写真实信息，我们将有工作人员在1-2个工作日内联系您。<br />禾祺科技不会将您的信息分享、
        售卖或交换给任何第三方公司
      </h4>
      <form class="inputBox">
        <input type="text" placeholder="公司/单位名称" />
        <input type="text" placeholder="姓名" />
        <input type="text" placeholder="手机号" />
        <button>提交信息</button>
      </form>-->
        <img src="../assets/images/qr_wx.png" alt="" />

    </div>
  </div>
</template>

<script>
import banner from './components/banner.vue';
export default {
  components:{
    banner
  },
  data() {
    return {

      offerData:[
        {
          name: "档案综合管理系统（信创单机版）",
          system:"限制用户数。",
          price:"9万8"
        }, {
          name: "档案综合管理系统（信创网络版）",
          system:"不限用户。",
          price:"49万8"
        }, {
          name: "智慧档案管理平台（高校版）",
          system:"不限用户。",
          price:"49万8"
        }, {
          name: "数字档案信息化管理平台（企业版）",
          system:"不限用户。",
          price:"49万8"
        },{
          name: "服务器",
          system:"信创目录",
          price:"8万-10万"
        }, {
          name: "数据库",
          system:"人大金仓",
          price:"10万"
        }, {
          name: "数字化加工服务",
          system:"按照国家标准制作数字档案",
          price:"按档案情况"
        }
      ],

      hopeList: [
        {
          identity: "为什么选择禾祺档案",
          content: "禾祺科技通过多年参与信息化建设服务的经验,具有一套优良的项目管理和质量监督体系,拥有一支稳定、 高效、创新、激情和强烈责任感的技术研发和项目实施队伍,具有专业的档案结构信息化处理运营能力、 数字化加工流程系统和档案信息类管理软件的研发能力,在档案信息处理、数字档案馆(室)、 数字化加工领域具有丰富的项目建设经验。同时在档案信息电子化管理、图文影像处理和人工智能识别方面 形成了自己的核心技术，并拥有多项自主研发的软件产品和专利技" ,
        },
        {
          identity: "伙伴权益",
          content:
              "签单支持： 共享禾祺档案业务规划方案、软件应用方案、客户案例以及业务系统资源，助您签单。" +
              "\n" +
              "产品支持： 全线产品全面提供开放集成，专业的技术团队全程协助产品实施。"+
              "\n" +
              "营销支持： 线上渠道、线下市场活动、印刷品等丰富的市场和营销推广机会。"
        },
        {
          identity: "产品拓展开发",
          content: "禾祺档案欢迎各类企业管理软件伙伴、企业电商服务伙伴、供应链和招投标领域专业咨询伙伴加盟，让我们在项目实践中围绕客户需求不断深化双方产品融合场景，为各个行业客户提供行业化、专业化的数字化档案管理解决方案。",
        },
        {
          identity: "申请加入",
          content: "欢迎识别下方二维码，专属顾问即刻与您联系",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>

.section-two {
  text-align: center;
  padding: 60px 200px;
  background-color: #f7fafe;
}

.section-two  ::v-deep table   thead {
  color: #0f9dfc;
}

/*.section-two h2 {
  font-size: 30px;
  color: #383838;
  font-weight: 400;
  margin-bottom: 25px;
}

.section-two p {
  font-size: 18px;
  color: #808089;
}

.section-two ul {
  display: flex;
  justify-content: space-around;
  padding: 0 100px;
  margin: 80px auto 40px;
}

.section-two ul li {
  flex: 1;
  margin: 0 2px;
}

.section-two ul li p:first-child {
  height: 107px;
  background-position: center center;
  background-size: contain;
}

.section-two ul li p:nth-child(2) {
  margin: 27px 0;
  font-size: 24px;
  color: #383838;
}*/

.section-three {
  text-align: center;
  padding: 80px 0;
  background-color: #f7fafe;
}

.section-three h2 {
  font-size: 30px;
  color: #383838;
  font-weight: 400;
  margin-bottom: 25px;
}

.section-three p {
  font-size: 18px;
  color: #808089;
  white-space: pre-wrap;
}

.section-three ul {
  margin: 60px auto 0;
  display: flex;
  flex-direction: column;
  padding: 0 200px;
}

.section-three ul li {
  flex: 1;
  padding: 50px 50px 50px 180px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 18px 54px rgba(240, 242, 244, 1);
  margin-bottom: 20px;
  position: relative;
  text-align: left;
}

.section-three ul li .left {
  font-size: 36px;
  color: #0f9dfc;
  font-weight: normal;
  position: absolute;
  top: 44px;
  left: 45px;
}

.section-three ul li .left::after {
  content: "";
  display: block;
  width: 1px;
  height: 100px;
  background: #d8e2ff;
  position: absolute;
  right: -10px;
  bottom: -60px;
  transform: rotate(30deg);
}

.section-three ul li .right h6 {
  font-size: 24px;
  color: #0f9dfc;
  margin-bottom: 20px;
  font-weight: normal;
}

.section-three ul li .right p {
  font-size: 18px;
  color: #808080;
}

.section-four {
  padding: 0 0 60px;
  text-align: center;
  background-color: #f7fafe;
}
.section-four img{
  width: 200px;
  height: 200px;
}
.section-four h4 {
  font-size: 18px;
  color: #808089;
  line-height: 1.8em;
  font-weight: 400;
  text-align: center;
}
.section-four .inputBox {
  margin-top: 100px;
  width: 468px;
  margin: 80px auto;
}
.section-four .inputBox input {
  width: 100%;
  margin-bottom: 25px;
  border-radius: 25px;
  font-size: 18px;
  padding: 8px 30px;
  height: 52px;
  border: 1px solid #c7c7c7;
}
.section-four .inputBox button {
  width: 260px;
  display: block;
  margin: 30px auto 0;
  color: #fff;
  background: #58bbfc;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  box-shadow: 4px 4px 6px rgba(88, 187, 252, 0.6);
}

@media (max-width: 1200px) {
  .section-three ul {
    padding: 0 150px;
  }
  .section-four .inputBox {
  }
}
@media (max-width: 992px) {
  .section-three ul {
    padding: 0 100px;
  }
}
@media (max-width: 768px) {
  .section-two{
	padding: 40px 0 20px;
  }
  .section-two h2{
	font-size: 24px;
  }
  .section-two p{
	font-size: 14px;
	padding: 0 10px;
  }
  .section-two ul{
	margin: 20px auto 40px;
	padding: 0 50px;
  }
  .section-two ul li p:first-child{
	height: 75px;
  }
  .section-two ul li p:nth-last-child(2){
	font-size: 18px;
	margin: 15px 0;
  }
  .section-three {
    padding: 40px 0;
  }
  .section-three h2{
    font-size: 24px;
    margin-bottom: 15px;
  }
  .section-three p{
    font-size: 14px;
  }
  .section-three ul {
    padding: 0 40px;
    margin: 30px auto 0;
  }
  .section-three ul li{
    padding: 30px 30px 30px 150px;
  }

  .section-four{
    padding: 20px 0;
  }
  .section-four h4{
    font-size: 16px;
    padding: 0 15px;
  }
  .section-four .inputBox{
    margin: 40px auto;
  }

}
@media (max-width: 442px) {
  .section-three ul {
    padding: 0 10px;
  }
  .section-three ul li{
	padding: 30px;
  }
  .section-three ul li .left{
	top:24px;
	left: 300px;
  }
  .section-four .inputBox {
    width: 300px;
    margin: 30px auto;
  }
  .section-four .inputBox input{
    font-size: 14px;
  }
  .section-four .inputBox button{
    margin-top: 10px;
    width: 130px;
  }
}
</style>
