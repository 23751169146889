<template>
  <div>
    <model :featureList="featureList"></model>
  </div>
</template>

<script>
import model from './model.vue';

export default {
  components: {
    model
  },
  data() {
    return {
      featureList: [{
        feature: '智能著录 ',
        description: [
          '支持对已上传的电子文件的筛选、删除、修改功能； 支持对已上传的电子文件进行件/组信息（标题、时间、拍摄者、人物、地点、内容等）整理。',
        ],
        src2: require('../../assets/images/solution-img-5.jpeg')
      }
      ]
    }
  },
  methods: {},
}
</script>

<style scoped>
</style>