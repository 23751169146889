<template>
    <div>
         <model :featureList="featureList"></model>
    </div>
</template>

<script>
import model from './model.vue';
export default {
    components:{
        model
    },
    data() {
        return {
            featureList: [
                {
					feature: '系统建设规范，权限控制缜密',
					description: [
						'以国家档案建设规范为基础，包括档案元数据方案、档号编制规则、档案著录规则、档案管理规范等',
						'支持角色、用户、权限自定义、三权分立。可控制用户能操作什么功能，能操作什么数据。',
					],
					src2: require('../../assets/images/insert-product-img3.jpg')
				},
				{
					feature: '自定义程度高，构建适用本单位的档案平台',
					description: [
						'根据不同业务自定义档案类型模板: 财会模板、文书模板、人事模板、婚姻模板、基建工程模板等，可无限制定义。',
						'根据档案实际管理情况，可自定义档号构成规则',
						'自定义档案目录采集表单，通过托拉拽即可完成'
					],
					src2: require('../../assets/images/procurement-img-2.jpg')
				},
				{
					feature: '自定义报表设计器，让档案统计不再固化',
					description: [
						'档案类目有多少? 文件存储情况? 数量有多少？利用情况如何？都是档案管理者必掌握的情况。 ',
						'多维度可视化数据报表，既能跟踪每类档案的使用情况，也能全局掌握档案保管与利用状况。 ',
						'结合客户自身的管理场景，为管理人员设置不同的数据范围权限及报表访问权限',
						'支持档案业务、档案系统服务监控数据可视化。为档案管理者、系统运维人员对系统的业务情况、运行情况提供可靠数据依据。'
					],
					src2: require('../../assets/images/procurement-img-3.jpg')
				}
				],
        }
    },
    methods: {

    },
}
</script>

<style scoped>

</style>