<template>
  <div>
    <banner
      :company-name="'禾祺科技'"
      :title="'档案管理新闻与咨询'"
      :description="'由纸质档案向电子化档案转型的必选工具。'"
      :background-url="require('@/assets/images/nav/zx2.jpeg')"



    ></banner>
    <oldInfoList></oldInfoList>
  </div>
</template>
  
  <script>
import banner from "./components/banner.vue";
import oldInfoList from "./components/oldInfoList.vue";
export default {
  components: {
    banner,
    oldInfoList,},
  data() {
    return {};
  },
};
</script>
  
<style lang="scss" scoped>
</style>