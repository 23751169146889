<template>
    <div>
        <div class="section-eight">
            <div class="bgImgBox" v-for="(obj, index) in featureList" :key="index">
                <div class="content">
                    <div class="left" data-aos="fade-right">
                        <div>
                            <p>{{ obj.feature }}</p>
                            <hr>
                            <ul>
                                <li v-for="item in obj.description">{{ item }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="right" data-aos="fade-left">
                        <img :src="obj.src2" alt="" :style="{boxShadow:$route.path=='/product/manage'&&index != featureList.length-1?'0 5px 10px 0 rgba(0,0,0,0.1)':'none'}">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props:['featureList'],
    data() {
        return {
           
        }
    },
    methods: {

    },
}
</script>

<style scoped>

.section-eight .content {
    display: flex;
    padding: 100px 0 100px;
    width: 70%;
    margin: 0 auto;
}

.section-eight .bgImgBox{
    padding: 0 0 15px;
}
.section-eight .bgImgBox:nth-child(1) .content {
    padding: 20px 0 0;
}

.section-eight .bgImgBox:nth-child(2n) {
    background-color: #f7fafe;
}

.section-eight .bgImgBox:nth-child(2n) .content {
    flex-direction: row-reverse;
}

.section-eight .bgImgBox:nth-child(2n) .content .left {
    padding-left: 80px;
}

.section-eight .content .left {
    flex: 4;
    padding-right: 80px;
    min-width: 300px;
    display: flex;
    align-items: center;
}

.section-eight .content .left p:first-child {
    font-size: 26px;
    color: #333;
    margin: 10px 0 0px;
    position: relative;
}

.section-eight .content .left hr {
    width: 27px;
    height: 3px;
    background: #0f9dfc;
    margin: 40px 0;
}

.section-eight .content .left ul {
    list-style-type: disc;
}

.section-eight .content .left ul li {
    font-size: 16px;
    color: #666;
    margin-bottom: 10px;
}

.section-eight .content .right {
    flex: 6;
    display: flex;
    align-items: center;
}

.section-eight .content .right img {
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

@media(max-width:768px){
    .section-eight .content .right{
        display: none;
    }
    .section-eight .bgImgBox .content{
        width: auto;
        padding: 30px 0;
    }
    .section-eight .bgImgBox .content .left{
        padding-left: 30px;
    }
    .section-eight .bgImgBox .content hr{
        display: none;
    }
    .section-eight .bgImgBox:nth-child(2n) .content .left{
        padding-right: 0;
    }
    .section-eight .content .left p:first-child{
        font-size: 20px;
    }
    .section-eight .content .left ul li{
        font-size: 14px;
    }
}
</style>