<template>
    <div>
         <model :featureList="featureList"></model>
    </div>
</template>

<script>
import model from './model.vue';
export default {
    components:{
        model
    },
    data() {
        return {
          featureList: [{
					feature: '声像档案',
					description: [
            "支持照片、视频、音频的导入，生成照片缩略图、视频自动截取封面。声像整理著录、人物信息标注、" +
            "元数据自动捕获、人脸智能检索照片、多人智能检索照片、元数据浏览、视频播放、以图搜索",
					],
					src2: require('../../assets/images/solution-img-4.jpg')
				},
				],
        }
    },
    methods: {

    },
}
</script>

<style scoped>

</style>