<template>
  <div>
    <banner
    :company-name="'禾祺科技'"
    :title="'档案管理客户案例'"
    :description="'由纸质档案向电子化档案转型的必选工具。'"
    :background-url="require('@/assets/images/nav/anli.webp')"
    ></banner>
    <customer></customer>
  </div>
</template>

<script>
import banner from './components/banner.vue';
import customer from './components/customer.vue';
export default {
  components:{
    banner,
    customer
  },
  data() {
    return {
      
    }
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  padding-top: 68px;
}
</style>