<template>
	<div>
		<div class="section-one">

      <el-dialog title="请填写联系方式,我们为您提供免费试用的账号" :visible.sync="open"  @close="cancel"
                 width="600px"  center   append-to-body >

        <div><span >姓名</span><el-input v-model="userName" placeholder="请输入内容" ></el-input></div>
        <div><span>所属单位</span><el-input v-model="affiliation"  placeholder="请输入内容"></el-input></div>
        <div><span>电话号码</span><el-input  v-model="phone" placeholder="电话号码"></el-input></div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="comimtForm">确 定</el-button>

          <el-button @click="cancel">取 消</el-button>
        </div>

      </el-dialog>
      <div style="padding: 100px 0">
        <h1>请专业顾问为您解答或演示</h1>
        <p>留下您的姓名和联系方式，会有专业工作人员免费为您服务</p>

        <div class="section-form">
          <div style="width: 250px; ">
            <el-input placeholder="请输入手机号码" v-model="phone"></el-input>
          </div>
          <div>
           <el-button  type="primary" @click="showOpen" >免费试用</el-button>
          </div>
        </div>
        <div class="dividingLine">
          <hr>
          <hr>
        </div>
      </div>
    </div>

	</div>
</template>

<script>
	import {insertUserInfo} from "@/api/kms/userInfo";
  import {Message} from "element-ui";

  export default {
		data() {
			return {
        open:false,
        phone:'',
        //用户姓名
        userName:'',
        //所属单位
        affiliation:'',
        contactList:[]
			}
		},
		methods: {
      showOpen(){
        this.open=true
      },
      cancel(){
        this.open = false;
      },
      comimtForm(){

        let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if(this.phone===''){

          this.$message({
            type: 'warning',
            message: '手机号不能为空'
          });
          return;
        }
        if(!reg_tel.test(this.phone)){

          this.$message({
            type: 'warning',
            message: '请输入正确格式的电话号码'
          });

          return;
        }
        if(this.userName===''){

          this.$message({
            type: 'warning',
            message: '姓名不能为空'
          });
          return
        }
        if(this.affiliation===''){
          this.$message({
            type: 'warning',
            message: '单位不能为空'
          });
          return;
        }
        let parm={phone: this.phone,userName:this.userName,affillation:this.affiliation};
        insertUserInfo(parm).then(response => {
          console.log('response',response)
          if(response.data.code=='200'){
            Message({
              message: '申请成功，我们的客户将在稍后与您进行联系',
              type: 'success'
            })
            this.cancel()
          }else {
            Message({
              message: response.data.msg,
              type: 'error'
            })
          }
        })
      }

		}
	}
</script>

<style scoped>
.section-form{
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 40px;
}

	.section-one {

		margin-top: 150px;
		margin-bottom: 0;
		background: url('../assets/images/contact-bg.jpg') no-repeat;
		background-size: contain;
		text-align: center;
    height: 500px;
	}
	.section-one p:first-child{
		font-size: 30px;
		color: #383838;
		margin-bottom: 20px;
	}
	.section-one p:last-child{
		font-size: 18px;
		color: #808089;
		line-height: 1.8em;
	}
	.section-one ul{
		display: flex;
		padding: 0 100px;
		margin: 80px auto 60px;
		justify-content: space-around;
	}
	.section-one ul li{
		flex: 1;
		background: #fff;
		margin: 0 10px;
		padding-bottom: 60px;
		box-shadow: 0 10px 20px rgba(77,138,221,0.15);
		position: relative;
	}
	.section-one ul li .blueArrow{
		display: none;
		position: absolute;
		left: 50%;
		margin-left: -7px;
	}
	.section-one ul li:hover .blueArrow{
		display: block;
	}
	.section-one ul li img:first-child{
		width: 80px;
		height: 80px;
		margin: 50px auto;
	}
	.section-one ul li h5{
		color: #383838;
		font-weight: normal;
		margin-top: 10px;
		margin-bottom: 20px;
		font-size: 18px;
	}
	.section-one ul li h6{
		font-size: 18px;
		color: #808080;
		margin-bottom: 36px;
		font-weight: 400;
	}
	.section-one .dividingLine{
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;
	}
	.section-one .dividingLine hr{
		width: 25%;
		height: 1px;
		border: 1px solid #0f9dfc;
	}
	.section-one .dividingLine hr:first-child{
		margin-left: 0;
	}
	.section-one .dividingLine hr:last-child{
		margin-right: 0;
	}
	
	.section-two{
		padding: 80px 0 40px;
		background: #f0f3f7;
	}
	.section-two h2{
		margin-bottom: 50px;
		font-size: 30px;
		font-weight: 400;
		text-align: center;
	}
	.section-two ul{
		display: flex;
		margin: 80px auto 60px;
		padding: 0 100px;
		justify-content: space-around;
	}
	.section-two ul li{
		flex: 1;
		background: #FFFFFF;
		margin: 0 10px;
		box-shadow: 0 5px 10px 0 rgba(77,138,221,0.3);
		margin-bottom: 24px;
		text-align: center;
		position:relative;
		overflow: hidden;
	}
	.section-two ul li p img{
		width: 100%;
	}
	.section-two ul li p:last-child{
		font-size: 20px;
		font-weight: 400;
		color: #333;
		padding: 20px 0;
	}
	.section-two ul li ol{
		transition: all .5s;
		position: absolute;
		top: 100%;
		width: 100%;
		height: 100%;
		color: #fff;
		background-color: #0f9dfc;
	}
	.section-two ul li:hover ol{
		position: absolute;
		top: 0px;
	}
	.section-two ul li ol li{
		background-color: transparent;
		box-shadow: none;
	}
	.section-two ul li ol li:first-child{
		font-size: 20px;
		font-weight: 400;
		color: #FFFFFF;
		padding-top:  30px;
	}
	.section-two ul li ol li:last-child{
		text-align: left;
		padding: 0 15px;
	}
	@media(max-width:1200px){
	}
	@media(max-width:992px){
		
	}
	@media(max-width:768px){
		.section-one{
			margin-top: 120px;
		}
		.section-one ul{
			padding: 0 50px;
			flex-direction: column;
			margin: 40px auto 40px;
		}
		.section-one ul li{
			margin: 10px 0;
			padding: 10px;
			display: flex;
			align-items: center;
			justify-content: space-evenly;
		}
		.section-one ul li .blueArrow{
			left:95%;
		}
		.section-one ul li img:first-child{
			margin: 0;
		}
		.section-one ul li h5,.section-one ul li h6{
			margin: 0;
			flex: 1;
		}
		.section-two{
			padding: 40px 0 20px;
		}
		.section-two h2{
			margin-bottom: 30px;
		}
		.section-two ul{
			margin: 40px auto 30px;
			flex-direction: column;
			padding: 0 30px;
		}
	}
	@media(max-width:562px){
	}
	@media(max-width:442px){
		.section-one ul li img:first-child{
			margin: 0;
			width: 60px;
			height: 60px;
		}
		.section-one ul li h5,.section-one ul li h6{
			font-size: 14px;
			margin: 0;
			flex: 1;
		}
		.section-one ul li .blueArrow{
			left:90%;
			bottom:10px;
			flex: 1;
		}
	}
</style>
